



const productSpec = [
  {
    name: 'name',
    title: 'Name',
    description: 'Name your product.',
    type: 'text',
    default: 'Duramic PLA+',
    value: null,
    units: null,
    range: []
  },
  {
    name: 'units',
    title: 'Units',
    description: 'How do you sell them? (individual, group of three, etc.)',
    type: 'text',
    default: 'set of 6 big risers/3 small risers',
    value: null,
    units: null,
    range: []
  },
  {
    name: 'amount',
    title: 'Amount',
    description: 'How many [units] of this product are sold at a time?',
    type: 'number',
    default: 1,
    value: null,
    units: null,
    range: []
  },
  {
   name: 'material',
   title: 'Material',
   description: 'Select an existing material, or add a new one.',
   type: 'Material',
   default: null,
   value: null,
   units: null,
   range: []
  },
  {
    name: 'materialAmount',
    title: 'Material Amount',
    description: "How much of this material (in material's native units) is used to create this product? (this should not include waste material)",
    type: 'number',
    default: 0,
    value: null,
    units: 'Material Units',
    range: []
  },
  {
    name: 'wasteMaterialAmount',
    title: 'Waste Material Amount',
    description: 'How much of your material goes to waste while creating this product?',
    type: 'number',
    default: 0,
    value: null,
    units: 'Material Units',
    range: []
  },
  {
    name: 'manufacturingDuration',
    title: 'Manufacturing Duration',
    description: 'How long does it take you to create this product? (in hours by default)',
    type: 'number',
    default: 0,
    value: null,
    units: 'hours',
    range: []
  },
  {
    name: 'designComplexity',
    title: 'Design Complexity',
    description: 'Rate the complexity of your design from 0-1. This is subjective and up to your own judgement.',
    type: 'number',
    default: 0,
    value: null,
    units: null,
    range: [0, 1]
  },
  {
    name: 'manufacturingComplexity',
    title: 'Manufacturing Complexity',
    description: 'Rate the complexity of the manufacturing process from 0-1. This is subjective and up to your own judgement.',
    type: 'number',
    default: 0,
    value: null,
    units: null,
    range: [0, 1]
  },
  {
    name: 'associatedInventoryCosts',
    title: 'Associated Inventory Costs',
    description: 'Select associated inventory costs or define some new ones.',
    type: 'list',
    default: 0,
    value: null,
    units: null,
    range: []
  },
  {
    name: 'costAdjustmentModifier',
    title: 'Cost Adjustment Modifier',
    description: 'Add an additional adjustment of your choosing. Use it to adjust your profit margin or factor in any other considerations you would like to make. This value can be positive or negative.',
    type: 'number',
    default: 0,
    value: null,
    units: 'dollars',
    range: []
  }
]

class Product {
  constructor (
    spec,
    specData,
    name,
    units,
    amount,
    material,
    materialAmount,
    manufacturingDuration,
    manufacturingComplexity,
    designComplexity,
    wasteMaterialAmount,
    associatedInventoryCosts,
    costAdjustmentModifier
  ) {
    if (spec) {
      this.name = specData.find(field => field.name === 'name')
      this.units = specData.find(field => field.name === 'units')
      this.amount = specData.find(field => field.name === 'amount')
      this.material = specData.find(field => field.name === 'material')
      this.materialAmount = specData.find(field => field.name === 'materialAmount')
      this.manufacturingDuration = specData.find(field => field.name === 'manufacturingDuration')
      this.manufacturingComplexity = specData.find(field => field.name === 'manufacturingComplexity')
      this.designComplexity = specData.find(field => field.name === 'designComplexity')
      this.wasteMaterialAmount = specData.find(field => field.name === 'wasteMaterialAmount')
      this.associatedInventoryCosts = specData.find(field => field.name === 'associatedInventoryCosts')
      this.costAdjustmentModifier = specData.find(field => field.name === 'costAdjustmentModifier')
    } else {
      this.name = name
      this.units = units
      this.amount = amount
      this.material = material
      this.materialAmount = materialAmount
      this.manufacturingDuration = manufacturingDuration
      this.manufacturingComplexity = manufacturingComplexity
      this.designComplexity = designComplexity
      this.wasteMaterialAmount = wasteMaterialAmount
      this.associatedInventoryCosts = associatedInventoryCosts
      this.costAdjustmentModifier = costAdjustmentModifier
    }
  }
  
  getEstimatedCost () {
    return this.material.costPerUnit * this.materialAmount
  }

  getWasteMaterialProportion () {
    return this.material.costPerUnit * this.wasteMaterialAmount
  }

  getFabricationModifier () {
    return this.manufacturingDuration * ( this.manufacturingComplexity + this.getWasteMaterialProportion() + this.designComplexity )
  }

  getAssociatedInventoryCosts () {
    let ret = 0
    this.associatedInventoryCosts.forEach(item  => ret += item.amount * item.costPerUnit)
    return ret
  }

  calculatePrice () {
    return this.getEstimatedCost()
      + this.getFabricationModifier()
      + this.getAssociatedInventoryCosts()
  }
}

const simpleProductSpec = [
  {
    name: 'name',
    title: 'Name',
    description: 'Name your product.',
    type: 'text',
    default: 'Plant Stand',
    value: null,
    units: null,
    range: []
  },
  {
    name: 'cost',
    title: 'Cost',
    description: 'What is the cost in materials for this product?',
    type: 'number',
    default: 3,
    value: null,
    units: null,
    range: []
  },
  {
    name: 'labor',
    title: 'Labor',
    description: 'How much does it cost to produce this product?',
    type: 'number',
    default: 2,
    value: null,
    units: null,
    range: []
  },
  {
    name: 'profit',
    title: 'Profit',
    description: 'Other cost adjustment',
    type: 'number',
    default: 1,
    value: null,
    units: null,
    range: []
  }
]

class SimpleProduct {
  constructor (
    name,
    cost,
    labor,
    profit
  ) {
    this.spec = simpleProductSpec

    this.name = name ?? this.getFieldSpec('name').default
    this.cost = cost ?? this.getFieldSpec('cost').default
    this.labor = labor ?? this.getFieldSpec('labor').default
    this.profit = profit ?? this.getFieldSpec('profit').default
  }

  calculatePrice () {
    return this.cost + this.labor + this.profit
  }

  getFieldSpec (fieldName) {
    return this.spec.find(field => field.name === fieldName)
  }
}

const supplySpec = [
  {
    name: 'name',
    title: 'Name',
    description: 'Name this supply.',
    type: 'text',
    default: 'Duramic PLA+',
    value: null,
    units: null,
    range: []
  },
  {
    name: 'unit',
    title: 'Unit',
    description: 'How do you measure this supply?',
    type: 'text',
    default: 'gram',
    value: null,
    units: null,
    range: []
  },
  {
    name: 'amount',
    title: 'Amount',
    description: 'How much of this supply comes in an order?',
    type: 'number',
    default: 1000,
    value: null,
    units: null,
    range: []
  },
  {
    name: 'cost',
    title: 'Cost',
    description: 'How much does an order of this supply cost?',
    type: 'number',
    default: 24.99,
    value: null,
    units: 'currency',
    range: []
  },
  {
    name: 'stock',
    title: 'Stock',
    description: 'How much of this product is left in stock?',
    type: 'number',
    default: 1,
    value: null,
    units: null,
    range: []
  }
]

class SupplyModel {
  constructor (
    name,
    unit,
    amount,
    cost,
    stock
  ) {
    this.spec = supplySpec

    this.name = name ?? this.getFieldSpec('name').default
    this.unit = unit ?? this.getFieldSpec('unit').default
    this.amount = amount ?? this.getFieldSpec('amount').default
    this.cost = cost ?? this.getFieldSpec('cost').default
    this.stock = stock ?? amount ?? this.getFieldSpec('stock').default
  }

  getCostPerUnit () {
    const cpu = this.cost / this.amount
    return cpu
  }

  getFieldSpec (fieldName) {
    return this.spec.find(field => field.name === fieldName)
  }
}

class InventoryItem {
  constructor (
    name,
    type,
    units,
    amount,
    costTotal
  ) {
    this.name = name
    this.type = type
    this.units = units
    this.amount = amount
    this.costTotal = costTotal
  }
}

class BulkInventoryItem {
  constructor (
    name,
    type,
    units,
    orderAmount,
    orderCostTotal
  ) {
    this.name = name
    this.type = type
    this.units = units
    this.orderAmount = orderAmount
    this.orderCostTotal = orderCostTotal
  }

  getCostPerUnit (unitCount) {
    return unitCount * ( this.orderCostTotal / this.orderAmount )
  }
}

// Get number as a currency-formatted string
const asCurrency = (value, currencySymbol) => {
  return `${currencySymbol ?? '$'}${value.toFixed(2)}`
}


export {
  Product,
  productSpec,
  SimpleProduct,
  simpleProductSpec,
  SupplyModel,
  InventoryItem,
  BulkInventoryItem,
  asCurrency
}
