<template>
  <div class="landing-container-main">

    <LandingImpression
      @sign-in="handleSignIn"
      @sign-up="handleSignUp"
      @get-started="handleGetStarted"
      class="landing-impression"
    />

    <LandingDetail classs="landing-detail" />

    <LandingRetainer class="landing-retainer" />

  </div>
</template>

<script>
  import LandingImpression from '@/views/landing/components/LandingImpression.vue'
  import LandingDetail from '@/views/landing/components/LandingDetail.vue'
  import LandingRetainer from '@/views/landing/components/LandingRetainer.vue'

  export default {
    name: 'Landing',
    components: { LandingImpression, LandingDetail, LandingRetainer },
    data () {
      return {

      }
    },
    computed: {
      isAuthenticated () {
        return !!this.$auth.currentUser
      }
    },
    methods: {
      handleSignUp () {
        this.$router.push({ name: this.$strings.auth.login.name, params: { action: 'signup' } })
      },
      handleSignIn () {
        this.$router.push({ name: this.$strings.auth.login.name })
      },
      handleGetStarted () {
        this.$router.push({ name: this.$strings.auth.login.name, params: { action: 'signup' } })
      },
      handleSubscribe () {}
    },
    created () {
      // Redirect to home if authenticated or for other reasons maybe
      if (this.isAuthenticated) { this.$router.push({ name: this.$strings.home.gettingStarted.name }) }
    }
  }
</script>

<style lang="scss" scoped>
.landing-container-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .landing-impression {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
  .landing-detail {
    width: 100%;
    height: 100%;
    flex-grow: 2;
  }
  .landing-retainer {
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
}
</style>