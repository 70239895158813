<template>
  <v-card
    color="grayscaleAccent"
    class="main-content elevation-1">
    <InfoField
      v-for="field in item.calculatedFieldMeta()"
      :key="field.name"
      :label="field.label"
      :value="getCurrency(field.name)"
      class="info-field"
    />
  </v-card
  >
</template>

<script>
import InfoField from '@/components/shared/InfoField.vue'
import { asCurrency } from '@/components/utils/pricing.js'

export default {
  name: 'ItemInfoPanel',
  props: [ 'value' ],
  components: { InfoField },
  data () {
    return {
      item: this.value
    }
  },
  methods: {
    getCurrency (callback) {
      return asCurrency(this.item[callback]())
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  background: var(--background-secondary);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  text-align: center;
  filter: var(--add-depth);
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 5px;

  .info-field {
    width: 100%;
    grid-column: 1;
    display: block;
    color: black;
    opacity: 0.8;
  }
}
</style>