<template>
    <div class="tutorial-container">
        <div v-if="showHelp" class="card-container">
            <HelpCard
                :items="imageList"
                @close="showHelp = !showHelp"
                class="card-viewer"
            />
        </div>
        <v-card v-else class="tutorial-card">
            <h1>{{ $strings.home.gettingStarted.title }}</h1>
            <p>{{ $strings.home.gettingStarted.intro }}</p>
            <p>{{ $strings.home.gettingStarted.nextStep }}</p>
            <v-btn class="start-button" @click="startTutorial">{{ $strings.home.gettingStarted.startButtonText }}</v-btn>
        </v-card>
    </div>
</template>

<script>
    import HelpCard from '@/components/shared/HelpCard.vue'
    import images from '@/assets/images/getting_started_cards/gettingStarted.js'

    export default {
        name: 'GettingStarted',
        components: { HelpCard },
        data () {
            return {
                imageList: images,
                showHelp: false,
                user: undefined
            }
        },
        methods: {
            async startTutorial () {
                this.showHelp = true
            },
        }
    }
</script>

<style lang="scss" scoped>
.card-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .card-viewer {
        width: 80%;
        height: auto;
    }
}
.tutorial-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .tutorial-card {
        padding: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h1 {
            margin-bottom: 10%;
        }
        p {
            align-self: center;
        }
        .start-button {
            margin-top: 10%;
            align-self: center;
        }
    }
}
</style>