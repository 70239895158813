import Row1Box1 from './Row1Box1.svg'
import Row1Box2 from './Row1Box2.svg'
import Row1Box3 from './Row1Box3.svg'
import Row2Box1 from './Row2Box1.svg'
import Row2Box2 from './Row2Box2.svg'
import Row2Box3 from './Row2Box3.svg'
import Row3Box1 from './Row3Box1.svg'
import Row3Box2 from './Row3Box2.svg'
import Row3Box3 from './Row3Box3.svg'

const images = [
    Row1Box1,
    Row1Box2,
    Row1Box3,
    Row2Box1,
    Row2Box2,
    Row2Box3,
    Row3Box1,
    Row3Box2,
    Row3Box3,
]

export default images