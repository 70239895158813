<template>
  <v-card class="elevation-2 item-div">
    <span @click="$emit('back')" class="title-container"><v-icon>mdi-arrow-left</v-icon><h2 class="title">{{ itemTitle }}</h2></span>
    <v-form ref="form" class="fields-div">
      <v-list-item v-for="field in itemFields" :key="field.name">
        <v-list-item-content class="field-content">
          <Field
            v-model.number="item[field.name]"
            :spec="value.getFieldSpec(field.name)"
          />
        </v-list-item-content>
      </v-list-item>
    </v-form>
  </v-card>
</template>

<script>

export default {
  name: 'ItemCreateForm',
  props: [ 'value' ],
  data () {
    return {
      item: this.value
    }
  },
  computed: {
    itemTitle () {
      if (this.value.name) {
        return this.value.name
      } else if (this.value.displayName) {
        return this.value.displayName
      } else {
        return 'New Item'
      }
    },
    itemFields () {
      return this.item && this.item.spec ? this.item.spec.filter(field => !field.hidden) : []
    }
  }
}
</script>

<style lang="scss" scoped>
.item-div {
  height: 100%;
  padding: 2%;
  background-color: white;

  .title-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;

    .title {
      height: 10%;
      margin-left: 15px;
    }
  }
  .fields-div {
    overflow-y: auto;
    height: auto;
  }
}
</style>