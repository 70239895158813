

const globalStrings = {
  general: {
    currency: '$'
  },
  home: {
    general: {
      name: 'Home'
    },
    gettingStarted: {
      name: 'GettingStarted',
      title: 'Getting Started',
      contentTitle: 'Getting Started with Abacus',
      intro: 'Use Abacus when you want to start selling something you make.',
      nextStep: 'We\'ll help you get started, click the button to begin!',
      startButtonText: 'Begin Tutorial'
    },
    welcomeMessage: 'Abacus was developed to help you develop a meaningful and consistent pricing model for your growing business. It helps you pin a number to those hard-to-quantify costs that make it difficult to judge what your work is really worth.',
    aboutTitle: 'About Abacus',
    aboutText: 'Abacus is a business tool meant to help individuals who seek to start a new business in the online retail market organize and grow their digital storefronts, and to navigate the financial and logistical challenges that come with that journey.',
  },
  landing: {
    general: {
      name: 'Welcome',
      title: 'Welcome'
    }
  },
  products: {
    new: {
      name: 'CreateProductView',
      title: 'Create a Product'
    },
    list: {
      name: 'ProductListView',
      title: 'My Products',
      overlayLoadingTemplate: `Getting your product data...`,
      overlayNoRowsTemplate: `It looks like you don't have any products yet.`
    },
    detail: {
      name: 'ProductDetailView',
      title: 'My Product'
    },
    general: {
      title: 'My Products',
      collection: 'SimpleProduct'
    }
  },
  supplies: {
    new: {
      name: 'CreateSupply',
      title: 'Create a Supply'
    },
    list: {
      name: 'SupplyListView',
      title: 'My Supplies',
      overlayLoadingTemplate: `Getting your supply data...`,
      overlayNoRowsTemplate: `It looks like you don't have any supplies yet.`
    },
    detail: {
      name: 'SupplyDetailView',
      title: 'My Supply'
    },
    general: {
      title: 'My Supplies',
      collection: 'Supply'
    }
  },
  users: {
    detail: {
      name: 'UserDetailView',
      title: 'My Profile'
    },
    general: {
      title: 'User',
      collection: 'users'
    }
  },
  integrations: {
    integrationQuery: 'What other services would you want to use with Abacus?',
    letUsKnow: 'Please let us know at mailto:integrations@shopabacus.com'
  },
  feedback: {},
  settings: {
    general: {
      name: 'Settings',
      title: 'Settings',
      component: 'Settings'
    }
  },
  auth: {
    login: {
      name: 'Login',
      title: 'Login',
      signupSuccess: 'Signup successful, welcome to Abacus!',
      accountRemovalSuccess: 'Account removed. Goodbye for now!'
    }
  },
  tutorial: {
    general: {
      disableTutorial: '(Don\'t show again)',
      title: 'Getting started with Abacus'
    }
  }
}

export default globalStrings
