<template>
  <div class="main-content">
    <v-img src="@/assets/logo.png" contain class="logo-image"></v-img>
    <h1 class="logo">abacus</h1>
    <p class="construction-note">{{ welcomeMessage }}</p>
    <h2 class="title">{{ aboutTitle }}</h2>
    <p class="construction-note">{{ aboutText }}</p>
    <p class="router-note">Please feel free to check out our <router-link :to="{name: 'Products'}" >Product</router-link> and <router-link :to="{name: 'CreateSupplies'}" >Supply</router-link> interfaces!</p>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {
      welcomeMessage: this.$strings.home.welcomeMessage,
      aboutTitle: this.$strings.home.aboutTitle,
      aboutText: this.$strings.home.aboutText
    }
  }
}
</script>

<style lang="scss" scoped>
.main-content {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 20px;
  padding-bottom: 20px;

  .logo-image {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    margin-top: -70px;
    margin-bottom: 45px;
  }
  h2 {
    margin-bottom: 25px;
    opacity: .75;
  }
  p {
    margin-bottom: 30px;
    opacity: .75;
  }
}
</style>