<template>
  <div class="integration-main">
    <v-card class="card-container">
      <p>What would you like to see here?</p>
      <span style="display: flex; flex-direction: row;">
        <p style="margin-right: 4px;">Please let us know at </p>
        <a :href="`mailto:${integrationEmail}`" >{{ integrationEmail }}</a>
      </span>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Integration',
    data () {
      return {
        integrationEmail: 'integrations@shopabacus.com'
      }
    }
  }
</script>

<style lang="scss" scoped>
.integration-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2%;

  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10%;

    p {
      text-align: center;
    }
  }
}
</style>