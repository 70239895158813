<template>
  <div id="login">
    <v-card :class="{ 'signup-form': !showSignupForm }" class="form-container">

      <!-- Password Input -->
      <PasswordReset v-if="showPasswordReset" @close="togglePasswordReset()"></PasswordReset>

      <!-- Sign Up Input -->
      <v-form v-else-if="showSignupForm">
        <v-list>
          <v-list-item>
            <h1>Get Started</h1>
          </v-list-item>

          <v-list-item>
            <v-text-field
              v-model.trim="signupForm.displayName"
              label="Name"
              type="text"
              placeholder="Saturn Labs"
              outlined
            ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-text-field
              v-model.trim="signupForm.email"
              label="Email"
              type="text"
              placeholder="you@email.com"
              outlined
            ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-text-field
              v-model.trim="signupForm.password"
              @keydown.enter="signup()"
              label="Password"
              type="password"
              placeholder="min 6 chars"
              outlined
            ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-btn
              @click="signup()"
              class="button"
              color="primary"
            >
              Sign Up
            </v-btn>
          </v-list-item>

          <v-list-item class="extras">
            <a @click="toggleForm()">Log In</a>
          </v-list-item>
        </v-list>
      </v-form>

      <!-- Login Input -->
      <v-form v-else >
        <v-list>
          <v-list-item>
              <h1>Welcome Back</h1>
          </v-list-item>

          <v-list-item>
            <v-text-field
              v-model.trim="loginForm.email"
              label="Email"
              type="text"
              placeholder="you@email.com"
              outlined
            ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-text-field
              v-model.trim="loginForm.password"
              @keydown.enter="login()"
              label="Password"
              type="password"
              placeholder="*****"
              outlined
            ></v-text-field>
          </v-list-item>

          <v-list-item>
            <v-btn @click="login()" class="button" color="primary" >Log In</v-btn>
          </v-list-item>
          <v-list-item>
            <div class="extras">
              <a @click="togglePasswordReset()">Forgot Password</a>
              <a @click="toggleForm()">Create an Account</a>
            </div>
          </v-list-item>
        </v-list>
      </v-form>

    </v-card>
  </div>
</template>

<script>
import { userFirebaseApi, User } from '@/models/users.js'
import PasswordReset from '@/components/forms/PasswordReset'

export default {
  name: 'Login',
  components: {
    PasswordReset
  },
  props: [ 'action' ],
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      signupForm: {
        displayName: '',
        email: '',
        password: ''
      },
      showSignupForm: false,
      showPasswordReset: false
    }
  },
  methods: {
    toggleForm() {
      if (this.showSignupForm) {
        this.$router.push({ name: this.$strings.auth.login.name })
      } else {
        this.$router.push({ name: this.$strings.auth.login.name, params: { action: 'signup' } })
      }
      this.showSignupForm = !this.showSignupForm
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset
    },
    login() {
      this.$store.dispatch('login', {
        email: this.loginForm.email,
        password: this.loginForm.password
      })
      .catch(err => this.$bus.$emit('alert', err.message, 'error', 'mdi-alert-circle'))
      .then(() => {
        this.$bus.$emit('login')
      })
    },
    signup() {
      this.$store.dispatch('signup', {
        email: this.signupForm.email,
        password: this.signupForm.password,
        displayName: this.signupForm.displayName,
      })
      .catch(err => {
        /*
         * The dispatch method keeps throwing errors for some reason
         * it is completing successfully, so we're just gonna drop this error for now
         */
        // this.$bus.$emit('alert', err.message, 'warning', 'mdi-alert-circle')
        console.warn('error:', err)
      })
      .then((res) => {
        const userModel = new User({
          uid: this.$auth.currentUser.uid,
          displayName: this.$auth.currentUser.displayName,
          email: this.$auth.currentUser.email
        })
        const userApiObject = userModel.toApi()
        userFirebaseApi.createUser(userApiObject)
        .then(() => {
          this.$bus.$emit('alert', this.$strings.auth.login.signupSuccess, 'success', 'mdi-alert-circle')
          this.$bus.$emit('signup')
        })
      })
      .catch(err => console.error(err.message))
    }
  },
  mounted () {
    // When page loads, apply prop state to form display state
    this.showSignupForm = this.action === 'signup'
  }
}
</script>

<style lang="scss" scoped>
#login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  .form-container {
    min-width: 40%;
    padding: 15px;

    h1 {
      margin-bottom: 30px;
    }
    .v-list-item {
      margin-bottom: 5px;
    }
    .button {
      width: 100%;
    }
    .extras {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
