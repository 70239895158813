<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      Submitted!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'SnackAlert.vue',
    props: [ 'value' ],
    data () {
      return {
        snackbar: this.value,
        timeout: 3000
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>