<template>
  <v-list class="labor-container">

    <h4 class="heading">Production Costs</h4>

    <v-list-item>
      <v-list-item-content>
        <v-text-field
          v-model="laborCost.productionHours"
          label="Production Hours"
          :messages="['How much time goes into making this product? (in hours)']"
          type="number"
          step="0.1"
          clearable
          @input="$emit('input', laborCost)"
          dense
          outlined
        />
      </v-list-item-content>
    </v-list-item>

    <v-list-item style="display: flex; flex-direction: row;">
      <v-list-item-content>
        <label class="field-label">Design Complexity</label>
        <v-slider
          v-model="laborCost.designComplexity"
          :messages="['A subjective measurement of how much of your time, effort, and skill it took to design this product.']"
          step="0.1"
          max="1"
          min="0"
          prepend-icon="mdi-numeric-1-circle"
          append-icon="mdi-numeric-10-circle"
          class="field-slider"
        />
      </v-list-item-content>
    </v-list-item>

    <v-list-item style="display: flex; flex-direction: row;">
      <v-list-item-content>
        <label class="field-label">Manufacturing Complexity</label>
        <!-- <v-chip class="field-chip">{{ laborCost.manufacturingComplexity }}</v-chip> -->
        <v-slider
          v-model="laborCost.manufacturingComplexity"
          :messages="['A subjective measurement of how much of your time, effort, and skill it takes to create this product.']"
          step="0.1"
          max="1"
          min="0"
          prepend-icon="mdi-numeric-1-circle"
          append-icon="mdi-numeric-10-circle"
          class="field-slider"
        />
      </v-list-item-content>
    </v-list-item>

    <v-list-item style="display: flex; flex-direction: row;">
      <v-list-item-content>
        <label class="field-label">Waste Material Proportion</label>
        <v-slider
          v-model="laborCost.wasteProportion"
          :messages="['How much waste material is generated when producing this product?']"
          step="0.1"
          max="1"
          min="0"
          prepend-icon="mdi-image-size-select-small"
          append-icon="mdi-image-size-select-large"
          class="field-slider"
        />
      </v-list-item-content>
    </v-list-item>
    
  </v-list>
</template>

<script>

  export default {
    name: 'LaborCostInput',
    props: [ 'value' ],
    data () {
      return {
        laborCost: this.value
      }
    }
  }
</script>

<style lang="scss" scoped>
.labor-container {
  padding: 10px;

  .v-list-item {
    margin-top: 5px;
    padding: 10px;

    .v-list-item-content {
      padding: 10px;
      display: inline-flex;
      flex-direction: row;

      .field-label {
        flex-grow: 1;
      }
      .field-chip {
        flex-grow: 1;
        margin-left: auto;
      }
      .field-slider {
        flex-grow: 1;
      }
    }
  }
}
</style>