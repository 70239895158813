import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import vuetify from './plugins/vuetify';
import store from './store'
import { auth } from './firebase/config'
import globalStrings from '@/assets/data/strings.js'

Vue.config.productionTip = false

// Global event bus
Vue.prototype.$bus = new Vue()
// Make string resources available throughout application
Vue.prototype.$strings = globalStrings
// Make auth status available throughout application
Vue.prototype.$auth = auth

// Register custom components
import Field from '@/components/forms/Field.vue'
Vue.component('Field', Field)
import RingSpinner from '@/components/spinners/RingSpinner.vue'
Vue.component('Spinner', RingSpinner)

let app

auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
  if (user) {
    store.dispatch('fetchUserProfile', user)
  }
})
