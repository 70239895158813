import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyAOyxAMouCCWjFcu1rUG_gQnZlcRiuDTdE",
  authDomain: "shoppricing-fd6b4.firebaseapp.com",
  databaseURL: "https://shoppricing-fd6b4-default-rtdb.firebaseio.com",
  projectId: "shoppricing-fd6b4",
  storageBucket: "shoppricing-fd6b4.appspot.com",
  messagingSenderId: "471635856505",
  appId: "1:471635856505:web:df1d78ac5f40b0c15398a8",
  measurementId: "G-HC9KV23LKH"
};

// init firebase
firebase.initializeApp(firebaseConfig)


const timestamp = firebase.firestore.FieldValue.serverTimestamp
const projectFirestore = firebase.firestore()
const auth = firebase.auth()
const usersCollection = projectFirestore.collection('users')
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)


// export utils/refs
export {
  timestamp,
  projectFirestore,
  auth,
  usersCollection
}
