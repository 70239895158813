<template>
  <div class="product-list-div">

    <div v-if="loading" class="spinner-div">
      <Spinner />
    </div>

    <div v-else-if="showHelp" class="card-container">
        <HelpCard
            :items="imageList"
            @close="showHelp = !showHelp"
            class="card-viewer"
        />
    </div>

    <v-card class="elevation-1 card-container" v-else>
      <ag-grid-vue style="width: 100%; height: 100%;"
        class="ag-theme-material product-list-table"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :rowData="rowData"
      >
      </ag-grid-vue>
    </v-card>

    <v-btn
      @click="$router.push({ name: createRoute })"
      color="primary"
      class="create-btn elevation-2"
    >
      Create New
    </v-btn>
    
  </div>
</template>

<script>
  import { AgGridVue } from "ag-grid-vue"
  import { productFirebaseApi, simpleProductSpec } from '@/models/products.js'
  import { userFirebaseApi } from '@/models/users.js'
  import HelpCard from '@/components/shared/HelpCard.vue'
  import images from '@/assets/images/getting_started_cards/gettingStarted.js'

  export default {
    name: 'ProductListView',
    components: { AgGridVue, HelpCard },
    data () {
      return {
        products: [],
        loading: true,
        showHelp: false,
        imageList: images,
        renderers: {},
        createRoute: this.$strings.products.new.name,
        gridOptions: {
          overlayLoadingTemplate: this.$strings.products.list.overlayLoadingTemplate,
          overlayNoRowsTemplate: this.$strings.products.list.overlayNoRowsTemplate,
          onRowClicked: this.onRowClicked
        },
        gridApi: null,
        columnApi: null,
        suppressAutoSizeColumns: ['name', 'createdAt']
      }
    },
    computed: {
      columnDefs () {
        return simpleProductSpec.
          sort((a, b) => a.sortOrder - b.sortOrder).
          map(product => {
            return {
              field: product.name,
              headerName: product.title,
              sortable: true,
              filter: true,
              resizable: true,
              suppressSizeToFit: this.suppressAutoSize(product.name),
              hide: !product.showInTable,
              cellRenderer: product.columnRenderer,
              cellRendererParams: { products: this.products }
            }
          })
      },
      rowData () {
        return this.products ? this.products.map(product => {
          let row = {}
          this.columnDefs.forEach(columnDef => {
            row[columnDef.field] = product[columnDef.field]
          })
          return row
        }) : []
      }
    },
    methods: {
      formatDate (date) {
        return date.getUTCDate() + "/" + (date.getUTCMonth() + 1).toString() + "/" + date.getUTCFullYear().toString()
      },
      getRenderers () {
        // Search spec for custom renderers and add to component-level renderer list
        simpleProductSpec.forEach(field => {
          if (field.columnRenderer !== null) { this.renderers[field.name] = field.columnRenderer }
        })
      },
      suppressAutoSize (col) {
        // These columns should be exempt from being auto-sized by the API
        return this.suppressAutoSizeColumns.includes(col)
      },
      onRowClicked (row) {
        this.$router.push({
          name: this.$strings.products.detail.name,
          params: {
            id: row.data.id
          }
        })
      },
      async getUserData () {
        const res = await userFirebaseApi.getUserByUid(this.$auth.currentUser.uid)
        return res
      }
    },
    async created () {
      // Get all products
      this.products = await productFirebaseApi.getProducts()
      this.user = await this.getUserData()
      this.showHelp = this.user.meta.tutorial
      this.loading = false
      // Search spec for renderers
      this.getRenderers()
    },
    mounted() {
      // Initialize APIs
      this.gridApi = this.gridOptions.api
      this.gridColumnApi = this.gridOptions.columnApi
      setTimeout(() => {
        // Couldn't get this to work with promises so it's going to have to be this awkward one second wait for now.
        this.gridColumnApi.autoSizeAllColumns()
      }, 1000)
    }
  }
</script>

<style lang="scss" scoped>
.product-list-div {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .card-container {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 5px;
  }
  .spinner-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>