<template>
  <div class="item-container">
    <div class="item-view">
      <ProductCreateForm v-model="product" @back="$router.go(-1)" class="left-column" />
      <ItemInfoPanel v-model="product" class="right-column" />
    </div>
    <div class="submit-container">
      <v-btn @click="handleSubmit" color="primary" class="submit">Create</v-btn>
      <SnackAlert v-model="snackalert" />
    </div>
  </div>
</template>

<script>
import { productFirebaseApi, SimpleProduct } from '@/models/products.js'
import ProductCreateForm from '@/views/products/components/ProductCreateForm.vue'
import ItemInfoPanel from '@/components/shared/ItemInfoPanel.vue'
import SnackAlert from '@/components/shared/SnackAlert.vue'

export default {
  name: 'CreateProductView',
  components: { ProductCreateForm, ItemInfoPanel, SnackAlert },
  data () {
    return {
      product: null,
      snackalert: false
    }
  },
  methods: {
    async handleSubmit () {
      const id = await productFirebaseApi.createProduct(this.product.toApi())
      this.snackalert = true
      this.$router.push({ name: this.$strings.products.list.name })
    }
  },
  async created () {
    this.product = new SimpleProduct()
  }
}
</script>

<style lang="scss" scoped>
.item-container {
  height: 100%;
  overflow: hidden;
  padding: 25px;

  .title {
    height: 10%;
    text-align: center;
    margin: 5px;
    font-size: 200%;
  }
  .item-view {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
    align-content: stretch;

    .left-column {
      display: flex;
      flex-basis: 63%;
      flex-direction: column;
      flex-grow: 2;
      height: auto;
    }
    .right-column {
      display: flex;
      flex-basis: 33%;
      flex-direction: column;
      flex-grow: 1;
      position: sticky;
      top: 0;
    }
  }
  .submit-container {
    padding-top: 20px;
    height: 10%;
    width: 100%;

    .submit {
      color: white;
      width: 100%;
    }
  }
}
</style>