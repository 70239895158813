import { projectFirestore, timestamp, auth } from '@/firebase/config'
import globalStrings from '@/assets/data/strings.js'
import { productFirebaseApi } from '@/models/products.js'
import { supplyFirebaseApi } from '@/models/supplies.js'


/*
  How models work:
  - Spec:
      The model spec contains metadata about each of the model's
      properties. This metadata is used by the front end to render
      pages dynamically. This allows us to minimize the UI components
      needed to bring a page to life.
  - Constructor:
      The constructor must provide a reference to the model spec and
      to its calculated field meta object. It should attempt to find
      a default value in the model spec for any fields whose value
      is empty
  - fromApi/toApi:
      These methods are used to gate off our models from whatever back
      end API we are using. For example, toApi strips out utility props
      like the product spec and calculated fields, because our backend
      does not need to know about these things.
  - Calculated Fields:
      An array of model methods. Methods listed in this property will
      be shown in the ItemInfoPanel component. You choose what goes into
      this array.
  - Firebase API:
      Methods specifically for talking to Firebase. This can be any back
      end API.
*/


/*
  Want to add a new field? Follow these steps:
  1. Add field and all meta to userSpec
  2. Add field to the model and its methods
  3. Validate that this will work with firebase
*/

// 1. userSpec
const userSpec = [
  {
    name: 'id',
    title: 'ID',
    description: 'The Abacus ID of this user.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: null,
    placeholder: 'ID',
    required: false,
    disabled: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: true,
    showInTable: false,
    sortOrder: -1,
    columnRenderer: null,
    sortable: false,
    filter: true
  },
  {
    name: 'meta',
    title: 'Metadata',
    description: 'Metadata for this user.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: {},
    placeholder: 'meta',
    required: false,
    disabled: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: true,
    showInTable: false,
    sortOrder: -1,
    columnRenderer: null,
    sortable: false,
    filter: false
  },
  {
    name: 'uid',
    title: 'UID',
    description: 'The Firebase uid of this user.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: null,
    placeholder: 'UID',
    required: false,
    disabled: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: true,
    showInTable: false,
    sortOrder: -1,
    columnRenderer: null,
    sortable: false,
    filter: true
  },
  {
    name: 'displayName',
    title: 'Name',
    description: 'User\'s name',
    prefix: '',
    suffix: '',
    type: 'text',
    default: null,
    value: null,
    units: null,
    range: [],
    hidden: false,
    getter: null,
    setter: null,
    placeholder: 'NAME',
    required: true,
    disabled: false,
    showInTable: true,
    sortOrder: 0,
    columnRenderer: null,
    sortable: true,
    filter: true
  },
  {
    name: 'email',
    title: 'Email',
    description: 'The user\'s email.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: null,
    value: null,
    units: null,
    range: [],
    hidden: false,
    getter: null,
    setter: null,
    placeholder: 'EMAIL',
    required: true,
    disabled: false,
    showInTable: true,
    sortOrder: 1,
    columnRenderer: null,
    sortable: true,
    filter: true
  },
  {
    name: 'photoURL',
    title: 'Photo',
    description: 'User\'s profile photo',
    prefix: '',
    suffix: '',
    type: 'text',
    default: null,
    value: null,
    units: null,
    range: [],
    hidden: false,
    getter: null,
    setter: null,
    placeholder: 'PHOTO',
    required: false,
    disabled: false,
    showInTable: false,
    sortOrder: -1,
    columnRenderer: null,
    sortable: false,
    filter: false
  },
  {
    name: 'storeName',
    title: 'Store Name',
    description: 'User\'s store\'s name',
    prefix: '',
    suffix: '',
    type: 'text',
    default: null,
    value: null,
    units: null,
    range: [],
    hidden: false,
    getter: null,
    setter: null,
    placeholder: 'STORE NAME',
    required: false,
    disabled: false,
    showInTable: true,
    sortOrder: 0,
    columnRenderer: null,
    sortable: true,
    filter: true
  }
]

// Spec helpers
const getUser = (item) => {
  return item.users.find(p => p.id === item.data.id)
}

// 2. Model and constructor
class User {
  /*
   *  Returns a user object with missing fields filled in with default values
   *
   *  @param {string} uid - The uid value from Firebase
   *  @param {object} meta - User metadata
   *      @param {bool} tutorial - Flag indicating whether a tutorial should be showed to user
   *      @param {int} tutorialStep - Current step in tutorial, starts at 0. Negative values disable tutorial
   *  @param {string} displayName - The name to be displayed in the UI
   *  @param {string} email - The email address used to register the user
   *  @param {string} photoURL - Identifier for the user's profile photo
   *  @param {string} storeName - The name of the user's shop
   *
   */
  constructor (args) {
    this.spec = userSpec
    this.uid = args.uid ?? this.getFieldSpec('uid').default
    this.meta = args.meta ?? this.getFieldSpec('meta').default
    this.displayName = args.displayName ?? this.getFieldSpec('displayName').default
    this.email = args.email ?? this.getFieldSpec('email').default
    this.photoURL = args.photoURL ?? this.getFieldSpec('photoURL').default
    this.storeName = args.storeName ?? this.getFieldSpec('storeName').default
  }

  toApi () {
    return {
      uid: this.uid,
      meta: this.meta,
      displayName: this.displayName,
      email: this.email,
      photoURL: this.photoURL,
      storeName: this.storeName
    }
  }

  /*
   *  Pull data from firebase into model object
   */
  fromApi (doc) {
    console.log('received doc:', doc)
    const data = { ...doc.data(), id: doc.id }
    console.log('mapping data:', data)

    this.id = data.id
    this.uid = data.uid
    this.meta = data.meta
    this.displayName = data.displayName
    this.email = data.email
    this.photoURL = data.photoURL
    this.storeName = data.storeName

    return this
  }

  getFieldSpec (fieldName) {
    return this.spec.find(field => field.name === fieldName)
  }

  calculatedFieldMeta () {
    return []
  }

  activateTutorial () {
    console.log('metadata exists:', typeof this.meta === 'object')
    console.log('existing metadata:', this.meta)

    // Create metadata if it doesn't already exist
    if (typeof this.meta !== 'object') {
      this.meta = {}
    }

    // initiate tutorial if it doesn't already exist
    if (!this.meta.tutorial) {
        // Set tutorial to true and start step counter
        this.meta.tutorial = true
        this.meta.tutorialStep = 0
    } else if (!this.meta.tutorialStep) {
      // tutorial was started, step was falsy, set to zero in case it isn't already zero
      this.meta.tutorialStep = 0
    }
    console.log('starting tutorial on step:', this.meta.tutorialStep)
  }

  advanceTutorial (step) {
    // Get current meta value
    let meta = typeof this.meta === 'object' ? this.meta : {}

    // Set tutorial to true and update meta in user object
    meta.tutorialStep = step

    // Update user meta field
    this.meta = meta
  }

  disableTutorial () {
    this.meta.tutorial = false
    userFirebaseApi.updateUser(this)
  }

}

// 3. firebase
const userFirebaseApi = {
  async getUser (id) {
    try {
      const res = await projectFirestore.collection(globalStrings.users.general.collection).doc(id).get()
      const user = new User()
      return user.fromApi(res)
    } catch (error) {
      console.error(error)
    }
  },
  async getUserByUid (uid) {
    try {
      const res = await projectFirestore
                            .collection(globalStrings.users.general.collection)
                            .where("uid", "==", uid)
                            .get()
                            .then((querySnapshot) => {
                              let results = []
                              querySnapshot.forEach((doc) => {
                                // doc.data() is never undefined for query doc snapshots
                                results.push(doc)
                              })
                              return results[0]
                            })
                            .catch((error) => {
                              console.error("Error getting documents: ", error);
                            })

      const user = new User({})
      return user.fromApi(res)
    } catch (error) {
      console.error(error)
    }
  },
  async getUsers () {
    try {
      // Get collection from firebase
      const res = await projectFirestore.collection(globalStrings.users.general.collection).get()
      // Use each record to instantiate a product object and return
      return Promise.all(res.docs.map(doc => {
        let user = new User()
        user.fromApi(doc)
        return user
      }))
    } catch (error) {
      console.error(error)
    }
  },
  async createUser (user) {
    try {
      const res = await projectFirestore.collection(globalStrings.users.general.collection).add(user)
      return res.id
    } catch (error) {
      console.error('createUser got error:', error)
      throw error.message
    }
  },
  async updateUser (user) {
    try {
      const userData = user.toApi()
      await projectFirestore.collection(globalStrings.users.general.collection).doc(user.id).update(userData)
              .catch(err => console.error('error updating user table:', err))
      await this.updateFirebaseUser(user)
    } catch (error) {
      console.error(error)
    }
  },
  async deleteUser (user) {
    try {
      // Remove user data from abacus
      await this.deleteUserProducts()
      await this.deleteUserSupplies()
      // Delete user from abacus data
      await projectFirestore.collection(globalStrings.users.general.collection).doc(user.id).delete()
      // Delete user from Firebase auth records
      await auth.currentUser.delete()
    } catch (error) {
      console.error(error)
    }
  },
  async updateFirebaseUser (userObject) {
    auth.currentUser.updateProfile({
      displayName: userObject.displayName,
      email: userObject.email,
      photoURL: userObject.photoURL
    }).then(function() {
      // Update successful.
      console.log('updated firebase user')
    }).catch(function(error) {
      // An error happened.
      console.error('failed to udate firebase user')
    })
  },
  async deleteUserProducts () {
    const products = await productFirebaseApi.getProducts()
    const ownProducts = products.filter(product => product.owner === auth.currentUser.uid)
    console.warn('products staged for delete:', ownProducts)
    ownProducts.forEach(product => {
      productFirebaseApi.deleteProduct(product.id)
    })
  },
  async deleteUserSupplies () {
    const supplies = await supplyFirebaseApi.getSupplies()
    const ownSupplies = supplies.filter(supply => supply.owner === auth.currentUser.uid)
    console.warn('supplies staged for delete:', ownSupplies)
    ownSupplies.forEach(supply => {
      supplyFirebaseApi.deleteSupply(supply.id)
    })
  },
}


export { userFirebaseApi, User, userSpec }
