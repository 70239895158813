import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import GettingStarted from '../views/home/components/GettingStarted.vue'
import Landing from '../views/landing/Landing.vue'
import Feedback from '../views/feedback/Feedback.vue'
import Integrations from '../views/integrations/Integrations.vue'
import ProductListView from '@/views/products/ProductListView.vue'
import ProductDetailView from '@/views/products/ProductDetailView.vue'
import CreateProductView from '@/views/products/CreateProductView.vue'
import CreateSupplyView from '@/views/supplies/CreateSupplyView.vue'
import SupplyDetailView from '@/views/supplies/SupplyDetailView.vue'
import SupplyListView from '@/views/supplies/SupplyListView.vue'
import globalStrings from '@/assets/data/strings.js'
import colors from '@/plugins/vuetify.js'
import Login from '@/views/login/Login.vue'
import Settings from '@/views/settings/Settings.vue'
import { auth } from '../firebase/config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/welcome',
    meta: {
      requiresAuth: false,
      title: 'Welcome',
      subtitle: 'Abacus',
      icon: null,
      main: false,
      color: colors.userPreset.theme.themes.light.primary.base,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/login/:action?',
    name: globalStrings.auth.login.name,
    component: Login,
    props: true,
    meta: {
      requiresAuth: false,
      title: globalStrings.auth.login.title,
      subtitle: 'Abacus',
      icon: null,
      main: false,
      color: colors.userPreset.theme.themes.light.primary.base,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  // TODO: Turn this into "Get Started" page
  {
    path: '/home',
    name: 'Home',
    redirect: '/welcome',
    // component: Home,
    meta: {
      requiresAuth: false,
      title: 'Home',
      subtitle: 'Abacus',
      icon: 'mdi-home',
      main: false,
      color: colors.userPreset.theme.themes.light.primary.base,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/welcome',
    name: globalStrings.landing.general.name,
    component: Landing,
    meta: {
      requiresAuth: false,
      title: globalStrings.landing.general.title,
      subtitle: 'Abacus',
      icon: null,
      main: false,
      color: colors.userPreset.theme.themes.light.primary.base,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/getting-started',
    name: globalStrings.home.gettingStarted.name,
    component: GettingStarted,
    meta: {
      requiresAuth: false,
      title: globalStrings.home.gettingStarted.title,
      subtitle: 'Abacus',
      icon: 'mdi-lifebuoy',
      main: true,
      color: colors.userPreset.theme.themes.light.primary.base,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/products',
    name: globalStrings.products.list.name,
    component: ProductListView,
    meta: {
      requiresAuth: true,
      title: globalStrings.products.list.title,
      subtitle: 'Create a Product',
      icon: 'mdi-printer-3d-nozzle',
      main: true,
      color: colors.userPreset.theme.themes.light.primary.product,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/products/create',
    name: globalStrings.products.new.name,
    component: CreateProductView,
    meta: {
      requiresAuth: true,
      title: globalStrings.products.new.title,
      subtitle: 'Create a Product',
      icon: null,
      main: false,
      color: colors.userPreset.theme.themes.light.primary.product,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/products/:id',
    name: globalStrings.products.detail.name,
    component: ProductDetailView,
    props: true,
    meta: {
      requiresAuth: true,
      title: globalStrings.products.detail.title,
      color: colors.userPreset.theme.themes.light.primary.product,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/supplies',
    name: globalStrings.supplies.list.name,
    component: SupplyListView,
    meta: {
      requiresAuth: true,
      title: globalStrings.supplies.general.title,
      subtitle: 'View All Supplies',
      icon: 'mdi-basket',
      main: true,
      color: colors.userPreset.theme.themes.light.primary.supply,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/supplies/:id',
    name: globalStrings.supplies.detail.name,
    component: SupplyDetailView,
    props: true,
    meta: {
      requiresAuth: true,
      title: globalStrings.supplies.detail.title,
      color: colors.userPreset.theme.themes.light.primary.supply,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/supplies/create',
    name: globalStrings.supplies.new.name,
    component: CreateSupplyView,
    meta: {
      requiresAuth: true,
      title: globalStrings.supplies.new.title,
      subtitle: 'Create a Supply',
      icon: 'mdi-ballot',
      main: false,
      color: colors.userPreset.theme.themes.light.primary.supply,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: Integrations,
    meta: {
      requiresAuth: true,
      title: 'Integrations',
      subtitle: 'Abacus',
      icon: 'mdi-connection',
      main: true,
      color: colors.userPreset.theme.themes.light.primary.base,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
    meta: {
      requiresAuth: false,
      title: 'Provide Feedback',
      subtitle: 'Tell us what you think',
      icon: 'mdi-black-mesa',
      main: true,
      color: colors.userPreset.theme.themes.light.primary.base,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  },
  {
    path: '/settings',
    name: globalStrings.settings.general.name,
    component: Settings,
    meta: {
      requiresAuth: true,
      title: 'Settings',
      subtitle: 'Abacus',
      icon: 'mdi-cog',
      main: true,
      color: colors.userPreset.theme.themes.light.primary.base,
      textColor: colors.userPreset.theme.themes.light.textReverse
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// navigation guard to check for logged in users
/*router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})*/
router.beforeEach((to,from,next) =>{
  const currentUser = auth.currentUser
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !currentUser) {
      next('/')
  }
  else {
      next()
  }
})
export default router
