<template>
  <div class="field-container">
    <h3 class="field-label heading">{{ label }}</h3>
    <label :for="value" class="field-value">{{ value }}</label>
  </div>
</template>

<script>
export default {
  name: 'InfoField',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      // type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.field-container {
  padding-left: 20px;
  padding-right: 20px;

  .field-label {
    font-family: "Helvetica";
    // font-weight: 600;
    // font-size: 14pt;
    opacity: 0.85;
  }
  .field-value {
    // font-family: "Helvetica";
    // font-weight: 200;
    font-size: 28pt;
    opacity: 0.75;
  }
}
</style>