<template>
    <div class="impression" :style="{backgroundImage: `url(${impressionBg})`}">

        <div class="impression-header">
            <div class="branding-badge">
                <v-img src="@/assets/logo-shpati.png" class="logo" contain></v-img>
                Abacus
            </div>

            <div class="auth-group">
                <v-btn class="auth-button" @click="$emit('sign-in')" color="grayscaleAccent">sign in</v-btn>
                <v-btn class="auth-button" @click="$emit('sign-up')" color="primary">sign up</v-btn>
            </div>
        </div>

        <h2 class="headline">Manage your shop like a boss</h2>

        <v-btn @click="$emit('get-started')" class="impression-footer" color="primary" x-large>Get Started</v-btn>
    </div>
</template>

<script>
    import impressionBg from '@/assets/images/landing/impression-opacity30.png'

    export default {
        name: 'LandingImpression',
        data () {
            return {
                impressionBg
            }
        },
        created () {
            console.log('this:', this)
        }
    }
</script>

<style lang="scss" scoped>
.impression {
    min-height: 75%;
    height: 100%;
    padding: 5%;
    padding-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow: hidden !important;
    background-position: center 100%;
    background-size: cover;

    .bg-bound {
        display: block;
        position: absolute;
        top: 0;
        z-index: 0;
        width: 100%;
        max-height: 100%;
        overflow: hidden !important;

        .impression-bg {
            margin: auto;
            opacity: 0.5;
            box-sizing: border-box;
            border: 1px solid transparent;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .impression-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 30px;
        align-self: flex-start;

        .branding-badge {
            font-family: Georgia;
            font-size: 5vw;
            opacity: 0.75;
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo {
                opacity: 1;
                max-height: 100%;
                max-width: 10vw;
            }
        }
        .auth-group {
            display: flex;
            flex-direction: row;
            
            .auth-button {
                margin-left: 2.5%;
                margin-right: 2.5%;
            }
        }
    }
    .headline {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 6vw !important;
        font-weight: 400;
        opacity: 0.8;
    }
    .impression-footer {
        width: 50%;
    }
}
</style>