<template>
    <div class="retainer-div" :style="{backgroundImage: `url(${retainerBg})`}">
        <h3>Be the first to know about updates and improvements to the site</h3>
        <p>Don't worry, we'll only let you know about the good stuff.</p>
        <span class="retainer-submit-input">
            <input
                placeholder="email"
                class="subscribe-input"
                type="text"
            >
            <div
                @click="$emit('subscribe')"
                class="subscribe-button"
            >Subscribe</div>
            <!-- <v-text-field
                v-model="subscriber"
                placeholder="email"
                class="subscribe-input"
                color="white"
                outlined
            >
                <div slot="append" class="subscribe-button">Subscribe</div>
            </v-text-field> -->
        </span>
    </div>
</template>

<script>
    import retainerBg from '@/assets/images/landing/retainer-cropped-opaque.png'

    export default {
        data () {
            return {
                retainerBg
            }
        }
    }
</script>

<style lang="scss" scoped>
.retainer-div {
    min-height: 75%;
    padding: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-position: center 100%;
    background-size: cover;
    color: white;

    h3 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 2.5vw;
        font-weight: 600;
        text-align: center;
    }
    p {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.5vw;
        font-weight: 100;
        padding-left: 10%;
        padding-right: 10%;
    }
    // Mobile version
    @media (max-width: 600px) {
        h3 {
            font-size: 5vw;
        }
        p {
            font-size: 3.5vw;
            font-weight: 300;
        }
    }
    .retainer-submit-input {
        width: 65%;
        height: 50px;
        margin-top: 5%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        border: 3px solid white;
        font-family: Arial, Helvetica, sans-serif;

        .subscribe-input {
            height: 100%;
            width: 75%;
            padding: 5px;
            padding-left: 10px;
            color: white;
            font-size: 1.75vw;
        }
        .subscribe-button {
            height: 100% !important;
            width: 25%;
            cursor: pointer;
            color: black;
            background-color: white;
            font-weight: 100;
            font-size: 1.75vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    // Mobile version
    @media (max-width: 600px) { 
        .retainer-submit-input {
            height: 50px;
        }
        .subscribe-button {
            font-style: bold;
        }
    }
}
</style>