<template>
  <div class="nav-user-detail">

    <v-list-item-content 
      v-if="user"
      @click="$router.push({ name: $strings.settings.general.name })"
      class="nav-container"
    >
      <v-list-item-title class="title">
        <v-icon>mdi-account-circle</v-icon>
        {{ name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ email }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-content v-else class="list-item-content">
      <v-btn
        @click="$router.push({ name: $strings.auth.login.name })"
        color="primary"
        class="log-in-btn"
      >
      <v-icon style="margin-right: 5%;">mdi-account-circle</v-icon>
      Log In</v-btn>
    </v-list-item-content>

  </div>
</template>

<script>
  export default {
    name: 'NavUserDetail',
    data () {
      return {
        user: null,
        name: null,
        email: null
      }
    },
    computed: {
      mainRoutes () {
        return this.$router.options.routes.filter(route => route.meta.main)
      }
    },
    methods: {
      updateProfile () {
        // Update display fields if user is logged in
        if (this.$auth.currentUser) {
          this.name = this.$auth.currentUser.displayName
          this.email = this.$auth.currentUser.email
          // Update user after setting display field values
          this.user = this.$auth.currentUser
        }
      }
    },
    created () {
      // Get user profile data
      this.updateProfile()

      // Event handlers
      this.$bus.$on('login', () => {
        this.updateProfile()
      })
      this.$bus.$on('signup', () => {
        this.updateProfile()
      })
      this.$bus.$on('updateProfile', () => {
        console.log('profile was updated...', this.$auth.currentUser)
        this.updateProfile()
      })
    }
  }
</script>

<style lang="scss" scoped>
.nav-user-detail {
  width: 100%;
  height: 100%;

  .nav-container {
    cursor: pointer;
  }
}
</style>