<template>
  <v-app id="inspire"  :theme="theme">

    <v-navigation-drawer
      v-if="showNav"
      v-model="drawer"
      class="elevation-3 nav-drawer"
      app
    >
      <NavDrawerContent :title="title" :subtitle="subtitle" />
      <v-btn v-if="currentUser" @click="logout" class="logout-button">Log Out</v-btn>
    </v-navigation-drawer>

    <v-app-bar
      v-if="showNav"
      app
      color="primary"
      class="elevation-4"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" color="textReverse" />
      <NavBarContent />
    </v-app-bar>
      
    <v-main app class="global">

      <div v-if="displayModal" class="modal-slot">
        <div class="modal">
          <!-- TODO: Add a template or something -->
        </div>
      </div>
      <router-view />
    
      <v-alert
        :value="alert"
        :type="alertType"
        :icon="alertIcon"
        transition="scale-transition"
        class="alert-message"
        @input="alert = false"
        dismissible
      >{{ alertMessage }}</v-alert>
    </v-main>

  </v-app>
</template>

<script>
// import '@/assets/css/main.css';
import Modal from '@/components/shared/Modal.vue'
import NavBarContent from '@/components/shared/NavBarContent.vue'
import NavDrawerContent from '@/components/shared/NavDrawerContent.vue'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: { Modal, NavBarContent, NavDrawerContent },
  data () {
    return {
      titlePrefix: 'Abacus - ',
      defaultTitle: 'Abacus',
      title: null,
      subtitle: null,
      displayModal: false,
      drawer: null,
      theme: 'light',
      landing: true,
      currentUser: this.$auth.currentUser,
      alert: false,
      alertMessage: 'Abacus',
      alertType: 'info',
      alertIcon: 'mdi-black-mesa'
    }
  },
  watch: {
    '$route' (to, from) {
      this.title = to.meta.title ? to.meta.title : this.defaultTitle
      this.subtitle = to.meta.subtitle ? to.meta.subtitle : null
      document.title = this.titlePrefix + this.title
    }
  },
  computed: {
    ...mapState(['userProfile']),
    currentRouteName () {
      return this.$route.name;
    },
    showNav () {
      return this.currentRouteName !== this.$strings.landing.general.name
    }
  },
  methods: {
    updateCurrentUser () {
      this.currentUser = this.$auth.currentUser
    },
    async logout () {
      await this.$store.dispatch('logout')
      this.updateCurrentUser()
      this.$router.push({ name: this.$strings.landing.general.name })
    }
  },
  created () {
    this.title = this.$route.meta.title ? this.$route.meta.title : this.defaultTitle
    this.subtitle = this.$route.meta.subtitle ? this.$route.meta.subtitle : null
    document.title = this.titlePrefix + this.title

    /*
      This listener handles incoming alert requests. To make a request, do this:

      doAlert() {
        this.$bus.$emit('alert', 'This is an alert', 'info', 'mdi-black-mesa')
      }
    */

    // Create bus listeners
    this.$bus.$on('login', () => {
      this.updateCurrentUser()
    })
    this.$bus.$on('logout', () => {
      this.logout()
    })
    this.$bus.$on('signup', () => {
      this.updateCurrentUser()
      // Navigate to the getting started page
      this.$router.push({ name: this.$strings.home.gettingStarted.name })
    })
    this.$bus.$on('updateProfile', () => {
      this.updateCurrentUser()
    })
    this.$bus.$on('alert', ( message, type='info', icon=null ) => {
      this.alertType = type
      this.alertIcon = icon
      this.alertMessage = message

      this.alert = true
    })
  }
};
</script>

<style lang="scss">
// Import styling from ag-grid
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";


.v-application {
   font-family: "Helvetica", sans-serif !important;
   font-weight: 200;

  .logo { // To pin point specific classes of some components
    font-family: "Georgia", sans-serif !important;
    font-weight: 600;
    font-size: 36pt;
    opacity: .79;
  }
  .title { // To pin point specific classes of some components
    font-family: "Helvetica", sans-serif !important;
    font-weight: 500;
    opacity: .75;
  }
  .heading { // To pin point specific classes of some components
    font-family: "Helvetica", sans-serif !important;
    font-weight: 400;
    opacity: .75;
  }
  .label { // To pin point specific classes of some components
    font-family: "Helvetica", sans-serif !important;
    font-weight: 300;
  }
  .logout-button {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .alert-message {
    position: fixed;
    width: 400px;
    max-width: 50%;
    right: 20px;
    bottom: 0px;
    overflow-wrap: normal;
  }
 }
.global {
  height: 100vh;
  background-color: #dfe6e9;
}
::-webkit-scrollbar {
  display: none;
}
.modal-slot {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
}
</style>
