<template>
  <div class="item-container">

    <v-dialog v-model="showDialog" width="50%" transition="dialog-bottom-transition" >
      <ConfirmationDialog v-model="showDialog" @yes="handleDelete" @no="showDialog = false" />
    </v-dialog>

    <div class="item-view">
      <ItemCreateForm v-model="item" @back="$emit('back')" class="left-column" />
      <ItemInfoPanel v-model="item" class="right-column" />
    </div>
    <div class="submit-container">
      <v-btn @click="handleSubmit(item)" color="primary" class="submit">Done</v-btn>
      <v-btn v-if="deleteButton" @click="softHandleDelete" color="error" class="delete"><v-icon>mdi-delete-forever</v-icon></v-btn>
    </div>
  </div>
</template>

<script>
import ItemCreateForm from '@/components/shared/ItemCreateForm.vue'
import ItemInfoPanel from '@/components/shared/ItemInfoPanel.vue'
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.vue'

export default {
  name: 'ItemView',
  components: { ItemCreateForm, ItemInfoPanel, ConfirmationDialog },
  props: [ 'value', 'delete' ],
  data () {
    return {
      item: this.value,
      deleteButton: this.delete,
      showDialog: false
    }
  },
  methods: {
    handleSubmit (item) {
      this.$emit('submit', item)
    },
    softHandleDelete (item) {
      this.showDialog = true
    },
    handleDelete (item) {
      this.showDialog = false
      this.$emit('delete', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.item-container {
  height: 100%;
  overflow: hidden;
  padding: 25px;

  .title {
    height: 10%;
    text-align: center;
    margin: 5px;
    font-size: 200%;
  }
  .item-view {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
    align-content: stretch;

    .left-column {
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      height: auto;
    }
    .right-column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      position: sticky;
      top: 0;
      padding: 5px;
    }
  }
  .submit-container {
    padding-top: 20px;
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .submit {
      color: white;
      flex-grow: 10;
    }
    .delete {
      color: white;
    }
  }
}
</style>