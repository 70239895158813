<template>
    <v-card class="elevation-1 getting-started-container">
        <div class="header-div">
            <v-icon class="close-button" @click="close">mdi-close</v-icon>
            <v-checkbox v-model="disableTutorial" :label="$strings.tutorial.general.disableTutorial" />
        </div>
        <div class="title-div">
            <h1>{{ $strings.tutorial.general.title }}</h1>
        </div>
        <v-carousel v-model="cardStep" v-if="cardStep >= 0">
            <v-carousel-item
                v-for="(item, i) in cardItems"
                :key="i"
                class="container-item"
            >
                <div class="image-div">
                    <v-img
                        :src="item"
                        contain
                        class="card-image"
                    ></v-img>
                </div>
            </v-carousel-item>
        </v-carousel>
        <div class="spinner-div" v-else>
            <Spinner />
        </div>
    </v-card>
</template>

<script>
    import { userFirebaseApi } from '@/models/users.js'

    export default {
        name: 'HelpCard',
        props: {
            items: {
                type: Array,
                required: true
            },
            step: {
                type: Number,
                default: 0,
                required: false
            }
        },
        data () {
            return {
                cardItems: this.items,
                cardStep: -1,
                disableTutorial: false,
                user: null
            }
        },
        watch: {
            cardStep () {
                this.user.advanceTutorial(this.cardStep)
                userFirebaseApi.updateUser(this.user).then(() => {
                this.$emit('input', this.cardStep)
            })
            }
        },
        methods: {
            getStep () {
                return this.user?.meta?.tutorialStep
            },
            close () {
                if (this.disableTutorial) {
                    console.warn('disabling tutorial...')
                    this.user.disableTutorial()
                }
                this.$emit('close')
            }
        },
        async created () {
            this.user = await userFirebaseApi.getUserByUid(this.$auth.currentUser.uid)
            this.user.activateTutorial()
            this.cardStep = this.getStep()
            await userFirebaseApi.updateUser(this.user)
        }
    }
</script>

<style lang="scss" scoped>
.getting-started-container {
    width: 100%;
    height: 100%;

    .header-div {
        display: flex;
        flex-direction: row;
        padding: 2%;
        gap: 4%;
    }
    .title-div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1%;

        h1 {
            font-size: xx-large;
            font-weight: 300;
        }
    }
    .container-item {
        width: 100%;
        height: 100%;

        .image-div {
            width: 100%;
            height: 100%;
            padding-bottom: 5%;

            .card-image {
                width: 100%;
                height: 100%;
            }
        }
    }
    .spinner-div {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10%;
    }
}
</style>