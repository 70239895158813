<template>
  <div class="elevation-2 settings-container">

    <div class="settings-form-container" >
      <div v-if="loading" class="spinner-div">
        <Spinner/>
      </div>
      <ItemCreateForm v-else-if="user" v-model="user" class="settings-form" />
      <div class="button-container">
        <v-btn @click="handleUpdate" block color="primary">Update</v-btn>
        <v-btn @click="handleDelete" block color="error">Delete</v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import { userFirebaseApi, User } from '@/models/users.js'
import ItemCreateForm from '@/components/shared/ItemCreateForm.vue'

export default {
  name: 'Settings',
  components: { ItemCreateForm },
  data() {
    return {
      name: this.safeName,
      email: this.safeEmail,
      showSuccess: false,
      user: undefined,
      loading: true
    }
  },
  computed: {
    userProfile () {
      return { ...this.$auth.currentUser }
    }
  },
  methods: {
    async getUserProfile (id) {
      return await userFirebaseApi.getUserByUid(id)
    },
    handleUpdate () {
      // Update abacus user
      userFirebaseApi.updateUser(this.user)
      // Update firebase user
      // this.updateProfile()
    },
    async handleDelete () {
      console.log('user ID:', this.user)
      await userFirebaseApi.deleteUser(this.user)
      this.$bus.$emit('alert', this.$strings.auth.login.accountRemovalSuccess, 'success', 'mdi-alert-circle')
      this.$bus.$emit('logout')
    }
  },
  async created () {
    console.log('finding user:', this.$auth.currentUser.uid)
    this.user = await this.getUserProfile(this.$auth.currentUser.uid)
    this.loading = false
    console.log('got user:', this.user)
  }
}
</script>

<style lang="scss" scoped>
.settings-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .settings-form-container {
    min-width: 50%;
    width: auto;
    height: auto;
    max-height: 90%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // overflow: auto;

    .settings-form {
      width: 100%;
      height: 100%;
      // margin-top: 5%;
      margin-bottom: 5%;
    }
    .spinner-div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .button-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      button {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
