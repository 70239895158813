<template>
    <v-card class="modal" >
        <div class="label">
            <h2>Are you sure you want to do that?</h2>
        </div>
        <div class="actions">
            <v-btn color="error" @click="$emit('yes')" >Yes</v-btn>
            <v-btn color="primary" @click="$emit('no')" >No</v-btn>
        </div>
    </v-card>
</template>

<script>
    export default {
        name: 'ConfirmationDialog',
        props: [ 'value' ],
        data () {
            return {
                showDialog: this.value
            }
        }
    }
</script>

<style lang="scss" scoped>
.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 7%;

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 5%;
        // border: 1px solid red;
    }
}
</style>