<template>
  <div class="main-content">
    <span class="title">{{ title }}</span>
    <v-img src="@/assets/logo.png" alt="@/assets/logo.png" contain class="logo" />
  </div>
</template>

<script>
  export default {
    name: 'NavBarContent',
    props: [ 'value' ],
    data () {
      return {
        defaultTitle: 'Abacus',
        title: this.$route.meta.title,
        drawer: this.value
      }
    },
    watch: {
      '$route' (to, from) {
        this.title = to.meta.title ?? this.defaultTitle
      }
    },
    methods: {
      toggleDrawer () {
        this.drawer = !this.drawer
        this.$emit('input', this.drawer)
      }
    },
    created () {
      this.title = this.$route.meta.title ? this.$route.meta.title : this.defaultTitle
    }
  }
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;

  .title {
    flex-grow: 10;
    color: white;
    text-overflow: show;
    // border: 1px solid red;
  }
  .logo {
    margin-left: auto;
    flex-grow: 1;
    max-height: 75px;
    max-width: 75px;
  }
}
</style>