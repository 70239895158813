<template>
  <v-card class="elevation-2 item-div">
    <span @click="$emit('back')" class="title-container"><v-icon>mdi-arrow-left</v-icon><h2 class="title">{{ itemTitle }}</h2></span>
    <v-form ref="form" class="fields-form">
      <v-list-item v-for="field in itemFields" :key="field.name" class="list-item">
        <v-list-item-content class="list-item-content">

          <!-- Cost Input -->
          <v-card
            v-if="field.type === 'cost'"
            elevation="2"
            class="cost-factor-container"
          >
            <CostFactorInput v-model="product" />
          </v-card>
          
          <!-- Labor Input -->
          <v-card
            v-else-if="field.type === 'labor'"
            elevation="2"
            class="labor-container"
          >
            <LaborCostInput v-model="product.labor" />
          </v-card>

          <!-- All Other Inputs -->
          <Field
            v-else
            v-model.number="product[field.name]"
            :spec="value.getFieldSpec(field.name)"
          />

        </v-list-item-content>
      </v-list-item>
    </v-form>
  </v-card>
</template>

<script>
import CostFactorInput from '@/components/forms/CostFactorInput.vue'
import LaborCostInput from '@/components/forms/LaborCostInput.vue'

export default {
  name: 'ItemCreateForm',
  components: { CostFactorInput, LaborCostInput },
  props: [ 'value' ],
  data () {
    return {
      product: this.value,
      supplyList: []
    }
  },
  computed: {
    itemTitle () {
      return this.value.name ? this.value.name : 'New Item'
    },
    itemFields () {
      return this.product.spec.filter(field => !field.hidden)
    }
  },
  methods: {
    updateField (event) {
      // this.value[] = event
      // $emit('input', )
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  },
  async created () {
    this.supplyList = await this.product.getFieldSpec('costs').getter()
  }
}
</script>

<style lang="scss" scoped>
.item-div {
  height: 100%;
  padding: 3%;
  margin-bottom: 5px;

  .title-container {
    cursor: pointer;
    display: flex;
    flex-direction: row;

    .title {
      height: 10%;
      margin-left: 15px;
    }
  }
  .fields-form {
    padding: 15px;
    overflow-y: auto;
    height: 85%;

    .list-item {
      padding: 5px;

      .list-item-content {
        padding: 5px;

        .cost-factor-container {
          padding: 5px;
        }
        .labor-container {
          padding: 15px;
        }
      }
    }
  }
}
</style>