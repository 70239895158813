import { projectFirestore, timestamp, auth } from '@/firebase/config'
import { supplyFirebaseApi, SupplyModel } from './supplies'
import globalStrings from '@/assets/data/strings.js'


/*
  How models work:
  - Spec:
      The model spec contains metadata about each of the model's
      properties. This metadata is used by the front end to render
      pages dynamically. This allows us to minimize the UI components
      needed to bring a page to life.
  - Constructor:
      The constructor must provide a reference to the model spec and
      to its calculated field meta object. It should attempt to find
      a default value in the model spec for any fields whose value
      is empty
  - fromApi/toApi:
      These methods are used to gate off our models from whatever back
      end API we are using. For example, toApi strips out utility props
      like the product spec and calculated fields, because our backend
      does not need to know about these things.
  - Calculated Fields:
      An array of model methods. Methods listed in this property will
      be shown in the ItemInfoPanel component. You choose what goes into
      this array.
  - Firebase API:
      Methods specifically for talking to Firebase. This can be any back
      end API.
*/


/*
  Want to add a new field? Follow these steps:
  1. Add field and all meta to simpleProductSpec
  2. Add field to the model and its methods
  3. Validate that this will work with firebase
*/

// 1. simpleProductSpec
const simpleProductSpec = [
  {
    name: 'id',
    title: 'ID',
    description: 'The ID of this supply.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: '',
    placeholder: 'ID',
    required: false,
    disabled: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: true,
    showInTable: false,
    sortOrder: -1,
    columnRenderer: null,
    sortable: false,
    filter: true
  },
  {
    name: 'owner',
    title: 'Owner',
    description: 'The ID of this product\'s owner.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: '',
    placeholder: 'Owner',
    required: false,
    disabled: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: true,
    showInTable: false,
    sortOrder: -1,
    columnRenderer: null,
    sortable: false,
    filter: true
  },
  {
    name: 'price',
    title: 'Price',
    description: 'Price of the product',
    prefix: '',
    suffix: '',
    type: 'number',
    default: null,
    value: null,
    units: 'currency',
    range: [],
    hidden: true,
    getter: null,
    setter: null,
    placeholder: 'PRICE',
    required: false,
    disabled: false,
    showInTable: true,
    sortOrder: 1,
    columnRenderer: (item) => {
      return globalStrings.general.currency + getProduct(item).calculatePrice().toFixed(2)
    },
    sortable: true,
    filter: true
  },
  {
    name: 'name',
    title: 'Name',
    description: 'Name your product.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: 'New Item',
    value: null,
    units: null,
    range: [],
    hidden: false,
    getter: null,
    setter: null,
    placeholder: 'NAME',
    required: true,
    disabled: false,
    showInTable: true,
    sortOrder: 0,
    columnRenderer: null,
    sortable: true,
    filter: true
  },
  {
    name: 'profit',
    title: 'Profit Margin',
    description: 'For retail, 45% is common. But this is up to you!',
    prefix: '%',
    suffix: '',
    type: 'number',
    default: 45,
    value: null,
    units: 'percent',
    range: [],
    hidden: false,
    getter: null,
    setter: null,
    placeholder: 'PROFIT',
    required: false,
    disabled: false,
    showInTable: true,
    sortOrder: 2,
    columnRenderer: (item) => {
      return globalStrings.general.currency + getProduct(item).calculateProfits().toFixed(2)
    },
    sortable: true,
    filter: true
  },
  {
    name: 'sku',
    title: 'SKU',
    description: 'Determine an SKU for your product if you\'d like.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: '',
    placeholder: 'SKU',
    required: false,
    disabled: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: false,
    showInTable: true,
    sortOrder: 5,
    columnRenderer: null,
    sortable: false,
    filter: true
  },
  {
    name: 'costs',
    title: 'Material Costs',
    description: 'What is the cost in materials for this product?',
    prefix: '',
    suffix: '',
    type: 'cost',
    default: [],
    value: null,
    units: null,
    range: [],
    hidden: false,
    // Getter and setter are for API calls
    getter: async () => {
      const supplies = await supplyFirebaseApi.getSupplies()
      return supplies
    },
    setter: null,
    placeholder: null,
    required: false,
    disabled: false,
    showInTable: true,
    sortOrder: 3,
    columnRenderer: (item) => {
      return globalStrings.general.currency + getProduct(item).supplyTotal().toFixed(2)
    },
    sortable: true,
    filter: true
  },
  {
    name: 'labor',
    title: 'Production Costs',
    description: 'How much does it cost to produce this product?',
    prefix: '',
    suffix: '',
    type: 'labor',
    default: {
      productionHours: 1,
      designComplexity: 1,
      manufacturingComplexity: 1,
      wasteProportion: 0
    },
    value: null,
    units: null,
    range: [],
    hidden: false,
    getter: null,
    setter: null,
    placeholder: null,
    required: false,
    disabled: false,
    showInTable: true,
    sortOrder: 4,
    columnRenderer: (item) => {
      return globalStrings.general.currency + getProduct(item).laborTotal().toFixed(2)
    },
    sortable: true,
    filter: true
  },
  {
    name: 'createdAt',
    title: 'Created At',
    description: 'Date and time this product was defined.',
    prefix: '',
    suffix: '',
    type: 'time',
    default: timestamp(),
    value: null,
    units: null,
    range: [],
    hidden: true,
    getter: null,
    setter: null,
    placeholder: null,
    required: false,
    disabled: false,
    showInTable: true,
    sortOrder: 6,
    columnRenderer: (item) => {
      return new Date(item.data.createdAt.seconds * 1000).toLocaleString()
    },
    sortable: true,
    filter: true
  }
]

// Spec helpers
const getProduct = (item) => {
  return item.products.find(p => p.id === item.data.id)
}

// 2. Model and constructor
class SimpleProduct {
  constructor (
    name = null,
    costs = null,
    labor = null,
    profit = null,
    sku = null
  ) {
    this.spec = simpleProductSpec

    this.name = name ?? this.getFieldSpec('name').default
    this.costs = costs ?? this.getFieldSpec('costs').default
    this.labor = labor ?? this.getFieldSpec('labor').default
    this.profit = profit ?? this.getFieldSpec('profit').default
    this.sku = sku ?? this.getFieldSpec('sku').default
    this.createdAt = timestamp()
  }

  toApi () {
    return {
      name: this.name,
      owner: this.owner,
      costs: this.costs.map(cost => {
        return {
          costFactor: cost.costFactor,
          amount: cost.amount,
          supply: cost.supply.id
        }
      }),
      labor: this.labor,
      profit: this.profit,
      sku: this.sku,
      createdAt: this.createdAt
    }
  }

  /*
   *  Pull data from firebase into model object
   */
  async fromApi (doc) {
    const data = await { ...doc.data(), id: doc.id }

    // console.log('getting costs')
    // Get cost factors
    this.costs = []   // reset cost list. I don't like this, but will have to do for now (fixes #23)
    // Use for loop instead of forEach because the latter doesn't support async calls
    // data.costs.forEach(cost => {
    for await (const cost of data.costs) {
      // console.log('cost:', cost)
      // Get supplies from each cost from firebase
      const supply = await supplyFirebaseApi.getSupply(cost.supply)
      // Calculate cost factor
      const costFactor = cost.amount * supply.getCostPerUnit()
      // // Add new cost factor to product
      // console.log('adding costs')
      this.costs.push({
        costFactor: costFactor,
        amount: cost.amount,
        supply: supply
      })
      // this.costs.push(cost)
    }

    // console.log('finished costs')
    this.id = data.id
    this.name = data.name
    this.owner = data.owner
    this.labor = data.labor
    this.profit = data.profit
    this.sku = data.sku
    this.createdAt = data.createdAt

    return this
  }

  costSummary () {
    return this.costs.map(cost => {
      if (cost.amount < 0 || cost.supply === null) {
        return ''
      } else {
        return `${cost.supply.name} @ ${cost.amount * cost.supply.getCostPerUnit()}`
      }
    }).join(', ')
  }

  calculateCosts () {
    return this.supplyTotal() + this.laborTotal()
  }

  calculatePrice () {
    return this.calculateCosts() * (1 + this.profit/100)
  }

  calculateProfits () {
    return this.calculatePrice() - this.calculateCosts()
  }

  supplyTotal () {
    let total = 0
    this.costs.forEach(cost => {
      total += cost.costFactor
    })
    return total
  }

  laborTotal () {
    const l = this.labor
    return l.productionHours * (l.designComplexity + l.manufacturingComplexity + l.wasteProportion)
  }

  updateCostFactor(pos, amount, supply) {
    if ( amount !== null && amount !== undefined ) { this.costs[pos].amount = amount }
    if ( supply !== null && supply !== undefined ) { this.costs[pos].supply = supply }
    if (this.costs[pos].amount >= 0 && this.costs[pos].supply !== null) {
      this.costs[pos].costFactor = (this.costs[pos].amount * this.costs[pos].supply.getCostPerUnit())
    }
  }

  getFieldSpec (fieldName) {
    return this.spec.find(field => field.name === fieldName)
  }

  calculatedFieldMeta () {
    return [
      {
        name: 'calculatePrice',
        label: 'Suggested Price',
        callback: this.calculatePrice,
        type: 'currency'
      },
      {
        name: 'calculateProfits',
        label: 'Profit',
        callback: this.calculateProfits,
        type: 'currency'
      },
      {
        name: 'supplyTotal',
        label: 'Material Costs',
        callback: this.supplyTotal,
        type: 'currency'
      },
      {
        name: 'laborTotal',
        label: 'Production Costs',
        callback: this.laborTotal,
        type: 'currency'
      }
    ]
  }
}

// 3. firebase
const productFirebaseApi = {
  async getProduct (id) {
    try {
      const res = await projectFirestore
                          .collection(globalStrings.products.general.collection)    // Products collection
                          .doc(id)                                                  // Filter by specific document
                          .get()                                                    // Execute query
      const product = new SimpleProduct()
      return product.fromApi(res)
    } catch (error) {
      console.error(error)
    }
  },
  async getProducts () {
    try {
      // Get collection from firebase
      const res = await projectFirestore
                          .collection(globalStrings.products.general.collection)    // Products collection
                          .where("owner", "in", [auth.currentUser.uid, 'public'])   // Filter by current user's resources
                          .get()                                                    // Execute query

      // Use each record to instantiate a product object and return
      let products = []
      for await (const doc of res.docs) {
        let product = new SimpleProduct()
        await product.fromApi(doc)
        products.push(product)
      }
      return products

      // return Promise.all(res.docs.map(doc => {
      //   let product = new SimpleProduct()
      //   product.fromApi(doc)
      //   return product
      // }))
    } catch (error) {
      console.error(error)
    }
  },
  async queryProducts (filters) {
    // filters should be list of { field, condition, value }
    let productRef = projectFirestore.collection(globalStrings.products.general.collection)
    filters.forEach(filter => {
      console.log()
      return productRef.where(filter.field, filter.condition, filter.value)
    })
    const products = await productRef.get()
    return Promise.all(products.docs.map(doc => {
      let product = new SimpleProduct()
      product.fromApi(doc)
      return product
    }))
  },
  async createProduct (product) {
    try {
      const res = await projectFirestore.collection(globalStrings.products.general.collection).add({...product, owner: auth.currentUser.uid})
      return res.id
    } catch (error) {
      console.error(error)
    }
  },
  async updateProduct (product) {
    try {
      await projectFirestore.collection(globalStrings.products.general.collection).doc(product.id).update({...product, owner: auth.currentUser.uid})
    } catch (error) {
      console.error(error)
    }
  },
  async deleteProduct (id) {
    try {
      await projectFirestore.collection(globalStrings.products.general.collection).doc(id).delete()
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
}


export { productFirebaseApi, SimpleProduct, simpleProductSpec }
