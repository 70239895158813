<template>
  <div v-if="loading" class="spinner-div">
    <Spinner/>
  </div>
  <ItemView
    v-else-if="supply"
    v-model="supply"
    :delete="true"
    @submit="handleSubmit"
    @delete="handleDelete"
    @back="$router.go(-1)"
  />
</template>

<script>
import { supplyFirebaseApi } from '@/models/supplies.js'
import ItemView from '@/components/shared/ItemView.vue'

export default {
  name: 'SupplyDetailView',
  props: [ 'id' ],
  components: { ItemView },
  data () {
    return {
      supply: null,
      loading: true
    }
  },
  methods: {
    async handleSubmit () {
      await supplyFirebaseApi.updateSupply(this.supply)
      this.$router.push({ name: this.$strings.supplies.list.name, })
    },
    async handleDelete () {
      await supplyFirebaseApi.deleteSupply(this.supply.id)
      this.$router.push({ name: this.$strings.supplies.list.name, })
    }
  },
  async created () {
    this.supply = await supplyFirebaseApi.getSupply(this.id)
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.supply-container {
  height: 100%;
  overflow: hidden;
  padding: 25px;

  .title {
    height: 10%;
    text-align: center;
    margin: 5px;
    font-size: 200%;
  }
  .supply-view {
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);

    .left-column {
      grid-column: 1;
      grid-row: 1;
    }
    .right-column {
      grid-column: 2;
      grid-row: 1;
      width: 100%;
    }
  }
  .submit-container {
    padding-top: 20px;
    height: 10%;
    width: 100%;

    .submit {
      color: var(--text-reverse);
      height: 90%;
      width: 100%;
      background: var(--accent-secondary);
      border-radius: 5px;
      filter: var(--add-depth);
    }
  }
}
.spinner-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>