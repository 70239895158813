<template>
  <div>

    <v-dialog
      v-model="overlay"
      transition="dialog-bottom-transition"
      width="auto"
      class="dailog-container"
    >
      <v-card class="modal" >
        <ItemView v-model="newSupply" @submit="handleNewSupply" @back="overlay = false" />
      </v-card>  
    </v-dialog>

    <v-list>

      <h4 class="section-header heading">Material Costs</h4>
      <p style="font-size: .8vw; margin-left: 15px;">Add materials from the My Supplies page</p>

      <!-- Cost Factor Elements -->
      <v-list-item v-for="(costFactor, index) in product.costs" :key="index">
        <v-list-item-content>
          <!-- Select Dropdown -->
          <v-select
            v-model="costFactor.supply"
            :items="formattedSupplies"
            item-text="title"
            item-value="name"
            label="Supply"
            @input="selectSupply($event, index)"
            dense
            outlined
          >
            <!-- Amount input -->
            <template v-slot:prepend>
              <v-text-field
                v-model="costFactor.amount"
                type="number"
                :label="`Amount (${costFactor.supply ? costFactor.supply.unit : 'units'})`"
                @input="selectAmount($event, index)"
                dense
                outlined
                class="amount-input"
              >

              </v-text-field>
            </template>

            <!-- Clear Input -->
            <template v-slot:append-outer>
              <v-btn v-if="costFactor.supply" icon :href="costFactor.supply.orderUrl" target="_blank"><v-icon>mdi-cart</v-icon></v-btn>
              <v-icon @click="removeCost(index)">mdi-close</v-icon>
            </template>
          </v-select>
        </v-list-item-content>
      </v-list-item>

      <!-- Add Cost Factor Button -->
      <v-list-item>
        <v-list-item-content>
          <span class="cost-factor-button-container">
            <v-btn @click="addCostFactor" color="primary" class="cost-button add">+ Add Cost Factor</v-btn>
            <v-btn @click="showOverlay" color="primary" class="cost-button new">+ New Cost Factor</v-btn>
          </span>
        </v-list-item-content>
      </v-list-item>

    </v-list>

  </div>
</template>

<script>
  import ItemView from '@/components/shared/ItemView.vue'
  import { SupplyModel, supplyFirebaseApi } from '@/models/supplies.js'

  export default {
    name: 'CostFactorInput',
    props: [ 'value' ],
    components: { ItemView },
    data () {
      return {
        product: this.value,
        supplies: [],
        currency: this.$strings.general.currency,
        overlay: false,
        newSupply: null
      }
    },
    computed: {
      formattedSupplies () {
        return this.supplies.map(supply => {
          return { ...supply, title: `${supply.name}`}
        })
      }
    },
    methods: {
      selectSupply (event, pos) {
        this.product.updateCostFactor(pos, null, this.supplies.find(supply => supply.name === event))

        this.$emit('input', this.product)
      },
      selectAmount (amount, pos) {
        this.product.updateCostFactor(pos, amount, null)
        this.$emit('input', this.product)
      },
      addCostFactor () {
        this.product.costs.push({
          amount: 1,
          costFactor: null,
          supply: null
        })
      },
      removeCost (index) {
        this.product.costs.splice(index, 1)
      },
      showOverlay () {
        this.newSupply = new SupplyModel()
        this.overlay = true
      },
      async handleNewSupply () {
        this.newSupply.id = await supplyFirebaseApi.createSupply(this.newSupply)
        this.addCostFactor()
        this.supplies.push(this.newSupply)
        this.selectSupply(this.newSupply.name, this.product.costs.length - 1)
        this.overlay = false
      },
      log (message) {
        console.log(message)
      }
    },
    async created () {
      this.supplies = await this.product.getFieldSpec('costs').getter()
    }
  }
</script>

<style lang="scss" scoped>
.dialog-container {
  padding: 2%;
}
.section-header {
  padding: 8px;
}
.amount-input {
  margin-top: -8px !important;
}
.cost-factor-button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .add {
    flex-grow: 10;
  }
  .new {
    flex-grow: 1;
  }
}
</style>