<template>
  <div class="field-container">
    <v-text-field
      v-model.trim="field"
      :label="spec.title"
      :messages="[spec.description]"
      :type="spec.type"
      :placeholder="spec.placeholder"
      :prefix="spec.prefix"
      :suffix="spec.suffix"
      step="1"
      clearable
      @input="$emit('input', field)"
      outlined
      dense
      elevation1
    />
  </div>
</template>

<script>

  export default {
    name: 'Field',
    props: [ 'value', 'spec' ],
    data () {
      return {
        field: this.value,
        selectItems: []
      }
    },
    computed: {
      formattedValue: {
        get: () => {
          return field
        },
        set: (newValue) => {
          this.field = newValue
        }
      }
    },
    methods: {
      async getSelectItems () {
        this.loading = true
        const selectOptions = await this.spec.getter()
        this.selectItems = selectOptions
        this.loading = false
      }
    },
    async created () {
      // Execute getter callback on select items
      if (this.spec.type === 'select') {
        await this.getSelectItems()
      }
    }
  }
</script>

<style lang="scss" scoped>
.field-container {
  // margin-top: 20px;
  // margin-left: 20px;
  // margin-right: 20px;
  // padding: 10px;
}
input {
  // margin: 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-outer-spin-button,
.v-input .v-input__control .v-input__slot .v-text-field__slot input::-webkit-inner-spin-button
{
-webkit-appearance: none;
margin: 0;
}
</style>