<template>
    <v-list class="detail">
        <v-list-item class="title">
            <h1>Turn your spreadsheets into a business</h1>
        </v-list-item>

        <v-list-item class="detail-blurb">
            <p>Abacus helps you develop sensible pricing for your products and helps you manage your inventory and expenses so you can get back to focusing on creating meaningful products and engaging with your customers.</p>
        </v-list-item>

        <v-list-item class="detail-items">
            <v-list class="detail-item-list">
                <v-list-item class="detail-item">
                    <v-img src="@/assets/images/landing/storefront.svg" class="detail-image" contain></v-img>
                    <h4>Know What Your Time is Worth</h4>
                    <p>Easily calculate how much to charge for your product. No more guessing at what your time is worth as a creator.</p>
                </v-list-item>

                <v-list-item class="detail-item">
                    <v-img src="@/assets/images/landing/finance.svg" class="detail-image" contain></v-img>
                    <h4>Better Than a Spreadsheet</h4>
                    <p>Retire that messy spreadsheet and start using Abacus to intelligently manage your inventory and shop assets.</p>
                </v-list-item>

                <v-list-item class="detail-item">
                    <v-img src="@/assets/images/landing/profit.svg" class="detail-image" contain></v-img>
                    <h4>Start Generating a Profit</h4>
                    <p>Start generating revenue and tracking your expenses to maximize your tax deductions. Don't worry, we'll make this easy.</p>
                </v-list-item>
            </v-list>
        </v-list-item>
    </v-list>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.detail {
    width: 100%;
    min-height: auto;
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        min-height: auto;
        width: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
        
        h1 {
            margin: auto;
            font-size: 4vw;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 600;
            opacity: 0.8;
        }
        @media (min-width: 600px) {
            font-size: 3vh;
        }
    }
    .detail-blurb {
        min-height: auto;
        width: 100%;
        padding-left: 10%;
        padding-right: 10%;
        margin-bottom: 8%;
        
        p {
            margin: auto;
            font-size: 3vw;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 100;
            text-align: center;
            opacity: 0.9;
        }
        // Mobile only
        @media (min-width: 600px) {
            p {
                font-size: 2.75vh
            }
        }
    }
    .detail-items {
        min-height: auto;
        width: 80%;

        .detail-item-list {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            // align-items: center;
            align-items: flex-start;
            flex-wrap: wrap;

            .detail-item {
                min-width: 200px !important;
                max-width: 80% !important;
                flex-basis: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 3%;
                // border: 1px solid blue;

                .detail-image {
                    height: 100%;
                    // margin-bottom: 5%;
                }
                h4 {
                    margin: auto;
                    margin-top: 5%;
                    margin-bottom: 4%;
                    font-size: 2vw;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: 600;
                    text-align: center;
                    opacity: 0.8;
                }
                @media (max-width: 600px) {
                    h4 {
                        font-size:4vw
                    }
                }
                p {
                    margin: auto;
                    font-size: 1.25vw;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: 100;
                    text-align: center;
                    opacity: 0.9;
                }
                @media (max-width: 600px) {
                    p {
                        font-size: 3vw
                    }
                }
            }
        }
    }
    // Mobile only
    @media (max-width: 600px) {
        .detail-items {
            width: 100%;
            margin-top: 10%;
        }
    }
}
</style>