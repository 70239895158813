import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

// const lightTheme = {
//   primary: '#41BFFA',
//   accent: '#49c5b6',
//   secondary: '#E8A87C',
//   ternary: "#dfe6e9",
//   grayscaleAccent: '#fafafa',
//   productColor: '#41BFFA',
//   supplyColor: '#41BFFA',
//   text: '#000000',
//   textReverse: '#FFFFFF',
//   success: '#4CAF50',
//   info: '#2196F3',
//   warning: '#FB8C00',
//   error: '#FF5252'
// }
const productTheme = {
  primary: '#49c5b6',
  accent: '#41BFFA',
  secondary: '#E8A87C',
  grayscaleAccent: '#fafafa',
  productColor: '#41BFFA',
  supplyColor: '#41BFFA',
  text: '#000000',
  textReverse: '#FFFFFF',
  success: '#4CAF50',
  info: '#2196F3',
  warning: '#FB8C00',
  error: '#FF5252'
}
const supplyTheme = {
  primary: '#E8A87C',
  accent: '#41BFFA',
  secondary: '#49c5b6',
  grayscaleAccent: '#fafafa',
  productColor: '#41BFFA',
  supplyColor: '#41BFFA',
  text: '#000000',
  textReverse: '#FFFFFF',
  success: '#4CAF50',
  info: '#2196F3',
  warning: '#FB8C00',
  error: '#FF5252'
}
const lightTheme = {
  primary: '#2d3436',
  accent: '#49c5b6',
  secondary: '#E8A87C',
  ternary: "#dfe6e9",
  grayscaleAccent: '#fafafa',
  productColor: '#41BFFA',
  supplyColor: '#41BFFA',
  text: '#000000',
  textReverse: '#FFFFFF',
  success: '#4CAF50',
  info: '#2196F3',
  warning: '#FB8C00',
  error: '#FF5252'
}

export default new Vuetify({
  theme: {
    defaultTheme: 'light',
    options: {
      customProperties: true
    },
    themes: {
      light: lightTheme,
      // dark: darkTheme,
      product: productTheme,
      supply: supplyTheme
    },
  },
});
