import globalStrings from '@/assets/data/strings.js'
import { projectFirestore, timestamp,  auth } from '@/firebase/config'
import { productFirebaseApi } from './products'

/*
  Want to add a new field? Follow these steps:
  1. Add field and all meta to supplySpec
  2. Add field to the model and its methods
  3. Validate that this will work with firebase
*/

// 1. supplySpec
const supplySpec = [
  {
    name: 'id',
    title: 'ID',
    description: 'The ID of this supply.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: '',
    placeholder: 'ID',
    required: false,
    disabled: false,
    suspendLink: true,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: true,
    showInTable: false,
    sortOrder: -1,
    columnRenderer: null,
    sortable: false,
    filter: true
  },
  {
    name: 'owner',
    title: 'Owner',
    description: 'The ID of this supply\'s owner.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: '',
    placeholder: 'Owner',
    required: false,
    disabled: false,
    suspendLink: true,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: true,
    showInTable: false,
    sortOrder: -1,
    columnRenderer: null,
    sortable: false,
    filter: true
  },
  {
    name: 'name',
    title: 'Name',
    description: 'Name this supply.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: '',
    placeholder: 'Supply Name',
    required: false,
    disabled: false,
    suspendLink: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: false,
    showInTable: true,
    sortOrder: 0,
    columnRenderer: null,
    sortable: true,
    filter: true
  },
  {
    name: 'unit',
    title: 'Unit',
    description: 'How do you measure this supply?',
    prefix: '',
    suffix: '',
    type: 'text',
    default: null,
    placeholder: 'gram, pouch, etc.',
    required: false,
    disabled: false,
    suspendLink: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: false,
    showInTable: false,
    sortOrder: 2,              // Since null and 0 are both falsy, ignored columns are -1 to make checks easier (if x < 0 then ignore)
    columnRenderer: null,
    sortable: true,
    filter: true
  },
  {
    name: 'pluralUnits',
    title: 'Units',
    description: 'How you refer to your units when there are more than one.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: null,
    placeholder: 'grams, pouches, etc.',
    required: false,
    disabled: false,
    suspendLink: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: false,
    showInTable: true,
    sortOrder: 3,
    columnRenderer: null,
    sortable: true,
    filter: true
  },
  {
    name: 'amount',
    title: 'Amount',
    description: 'How much of this supply comes in an order?',
    prefix: '',
    suffix: '',
    type: 'number',
    default: null,
    placeholder: 'Supply Amount',
    required: false,
    disabled: false,
    suspendLink: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: false,
    showInTable: true,
    sortOrder: 4,
    columnRenderer: null,
    sortable: true,
    filter: true
  },
  {
    name: 'cost',
    title: 'Cost',
    description: 'How much does an order of this supply cost?',
    prefix: '',
    suffix: '',
    type: 'number',
    default: null,
    placeholder: 'Supply Cost',
    required: false,
    disabled: false,
    suspendLink: false,
    value: null,
    units: 'currency',
    pluralUnits: 'currency',
    range: [],
    hidden: false,
    showInTable: true,
    sortOrder: 1,
    columnRenderer: null
  },
  {
    name: 'stock',
    title: 'Stock',
    description: 'How much of this do you have left?',
    prefix: '',
    suffix: '',
    type: 'number',
    default: null,
    placeholder: 'Units in Stock',
    required: false,
    disabled: false,
    suspendLink: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: false,
    showInTable: true,
    sortOrder: 4,
    columnRenderer: null,
    sortable: true,
    filter: true
  },
  {
    name: 'createdAt',
    title: 'Created At',
    description: 'The time when this item was defined.',
    prefix: '',
    suffix: '',
    type: 'time',
    default: timestamp(),
    placeholder: '',
    required: false,
    disabled: false,
    suspendLink: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: true,         // Hidden is used to disable this input when creating an element
    showInTable: true,     // Used to show or hide field when displaying in ag-grid
    sortOrder: 7,
    columnRenderer: (item) => { return new Date(item.data.createdAt.seconds * 1000).toLocaleString() },
    // columnRenderer: 'dateComponent',
    sortable: true,
    filter: true
    // columnRenderer: () => { return new Date(item.createdAt.seconds * 1000).toLocaleString() }
  },
  {
    name: 'orderUrl',
    title: 'Order URL',
    description: 'Remind yourself where you got this supply for next time.',
    prefix: '',
    suffix: '',
    type: 'text',
    default: null,
    suspendLink: true,
    placeholder: 'amazon.com/your-supply-url',
    required: false,
    disabled: false,
    value: null,
    units: null,
    pluralUnits: null,
    range: [],
    hidden: false,
    showInTable: true,
    sortOrder: 6,
    columnRenderer: (item) => { return `<a href="${item.data.orderUrl}" target="_blank"><img src="https://img.icons8.com/material/24/000000/shopping-cart--v1.png"/></a>` }
  }
]

// 2. Model and constructor
class SupplyModel {
  constructor (
    name = null,
    unit = null,
    pluralUnits = null,
    amount = null,
    cost = null,
    stock = null,
    orderUrl = null
  ) {
    this.spec = supplySpec

    this.name = name ?? this.getFieldSpec('name').default
    this.unit = unit ?? this.getFieldSpec('unit').default
    this.pluralUnits = pluralUnits ?? this.getFieldSpec('pluralUnits').default
    this.amount = amount ?? this.getFieldSpec('amount').default
    this.cost = cost ?? this.getFieldSpec('cost').default
    this.stock = stock ?? amount ?? this.getFieldSpec('stock').default
    this.createdAt = timestamp()
    this.orderUrl = orderUrl ?? this.getFieldSpec('orderUrl').default
  }

  // Map model class to firebase API request object
  toApi () {
    return {
      name: this.name,
      owner: this.owner,
      unit: this.unit,
      pluralUnits: this.pluralUnits,
      amount: this.amount,
      cost: this.cost,
      stock: this.stock,
      createdAt: this.createdAt,
      orderUrl: this.orderUrl
    }
  }


  //  Pull data from firebase and map to model object
  fromApi (doc) {
    const data = { ...doc.data(), id: doc.id }

    this.id = data.id
    this.name = data.name
    this.owner = data.owner
    this.unit = data.unit
    this.pluralUnits = data.pluralUnits
    this.amount = data.amount
    this.cost = data.cost
    this.stock = data.stock
    this.createdAt = data.createdAt
    this.orderUrl = data.orderUrl

    return this
  }

  // Calculate the material cost to produce this product
  getCostPerUnit () {
    // Make sure values are populated
    if (this.cost !== null && this.amount !== null) {
      return (this.cost / this.amount)
    } else {
      // Not enough data to provide meaningful output
      return 0
    }
  }

  formattedCostPerUnit (currency) {
    currency = currency ? currency : '$'
    return currency + this.getCostPerUnit().toFixed(2) + '/' + this.unit
  }

  // Return the model class's spec
  getFieldSpec (fieldName) {
    return this.spec.find(field => field.name === fieldName)
  }

  // Gather calculated fields to feed into info panel component
  calculatedFieldMeta () {
    return [
      {
        name: 'getCostPerUnit',
        label: 'Cost Per Unit',
        callback: this.getCostPerUnit,
        type: 'currency'
      }
    ]
  }

}

// 3. Firebase API
const supplyFirebaseApi = {
  /* Helper Methods */
  async findDownstreamDependencies (supplyId, productList) {
    // console.log('finding uses of', supplyId)
    // console.log('product list:', productList)
    return productList.filter(product => {
      product.costs.forEach(cost => {
        console.log(cost.supply.id, '===', supplyId)
        if (cost.supply.id === supplyId) return true
      })
      return false
    })
  },


  /* Main Class Methods */
  // Get single supply from Firebase
  async getSupply (id) {
    try {
      const res = await projectFirestore
                          .collection('Supply')     // Supply collection
                          .doc(id)                  // Use ID passed to method
                          .get()                    // Execute query

      const supply = new SupplyModel()
      return supply.fromApi(res)
    } catch (error) {
      console.error('getSupply error:', error)
    }
  },
  // Get list of all supplies from Firebase
  async getSupplies () {
    try {
      const res = await projectFirestore
                          .collection('Supply')                                     // Supply collection
                          .where("owner", "in", [auth.currentUser.uid, 'public'])   // Owned by logged in user or public
                          .get()                                                    // Execute query

      return Promise.all(res.docs.map(doc => {
        return new SupplyModel().fromApi(doc)
      }))
    } catch (error) {
      console.error('getSupplies error:', error)
    }
  },
  // Create a new supply in Firebase
  async createSupply (supply) {
    try {
      // Set owner to current user
      supply.owner = auth.currentUser.uid

      const res = await projectFirestore
                          .collection('Supply')     // Supply collection
                          .add(supply.toApi())      // Add Supply
      return res.id
    } catch (error) {
      console.error('createSupply error:', error)
    }
  },
  // Update an existing supply in Firebase
  async updateSupply (supply) {
    try {
      // Set owner to current user. If not owner, this will fail and provide an error.
      supply.owner = auth.currentUser.uid

      // Send firebase new supply data
      await projectFirestore
              .collection('Supply')       // Supply collection
              .doc(supply.id)             // Use document supplied in params
              .update(supply.toApi())     // Call toApi on supply and send to firebase
    } catch (error) {
      console.error('updateSupply error:', error)
    }
  },
  async deleteSupply (id) {
    try {
      // Query products to find references to this supply
      const products = await productFirebaseApi.getProducts()

      // Find products that use this supply as a cost factor
      const deps = products.filter(product => {
        let ret = false
        product.costs.forEach(cost => {
          if (cost.supply.id === id) ret = true
        })
        return ret
      })

      // If this supply is still in use, do not delete
      if (deps.length) {
        const dependencyNames = deps.map(dep => dep.name)
        throw(`Failed to delete supply, it is being used by ${dependencyNames}`)
      }

      // The supply is not being used, go ahead and delete it
      await projectFirestore.collection(globalStrings.supplies.general.collection).doc(id).delete()

      // Let calling method know the delete was successful
      return { status: true }

    } catch (error) {
      console.error(error)
      // Let calling method know that did not go well
      return { status: false, error: error }
    }
  }
}

export { supplyFirebaseApi, SupplyModel, supplySpec }
