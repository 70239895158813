<template>
  <div class="nav-drawer-main">

    <v-list-item class="elevation-1" >
        <NavUserDetail/>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in mainRoutes"
          :key="item.meta.title"
          link
          :to="{ name: item.name }"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.meta.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.meta.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

  </div>
</template>

<script>
  import NavUserDetail from '@/components/shared/NavUserDetail.vue'
  export default {
    name: 'NavDrawer',
    props: [ 'title', 'subtitle' ],
    components: { NavUserDetail },
    data () {
      return {
        // title: null
      }
    },
    computed: {
      mainRoutes () {
        return this.$router.options.routes.filter(route => route.meta.main)
      }
    }
  }
</script>

<style lang="scss" scoped>
.nav-drawer-main {
  font-family: "Helvetica";
}
</style>