<template>
  <div class="feedback-main">
    <v-card class="card-container">
      <p style="margin-right: 4px;">Please submit feedback to <a :href="`mailto:${feedbackEmail}`" >{{ feedbackEmail }}</a>.</p>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'Feedback',
    data () {
      return {
        feedbackEmail: 'feedback@shopabacus.com'
      }
    }
  }
</script>

<style lang="scss" scoped>
.feedback-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2%;

  .card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10%;
  }
}
</style>