<template>
  <div class="modal-content">
    <v-list>
      
      <v-list-item @click="$emit('close')" class="close">
        <v-icon>mdi-close</v-icon>
      </v-list-item>
      <v-list-item>
        <h3>Reset Password</h3>
      </v-list-item>
      <v-list-item v-if="!showSuccess" class="subform">
        <v-list>
          <v-list-item>
            <form @submit.prevent>
              <v-text-field
                v-model.trim="email"
                label="Email"
                type="email"
                placeholder="you@email.com" 
                messages="Enter your email to reset your password"
              />
            </form>
          </v-list-item>
          <v-list-item>
            <p v-if="errorMsg !== ''" class="error">{{ errorMsg }}</p>
          </v-list-item>
          <v-list-item>
            <v-btn
              @click="resetPassword()"
              class="button"
              color="primary"
            >
              Reset
            </v-btn>
          </v-list-item>
        </v-list>
      </v-list-item>
      <v-list-item v-else>Success! Check your email for a reset link.</v-list-item>
    </v-list>
  </div>
</template>

<script>
import { auth } from '@/firebase/config.js'

export default {
  data() {
    return {
      email: '',
      showSuccess: false,
      errorMsg: ''
    }
  },
  methods: {
    async resetPassword() {
      this.errorMsg = ''

      try {
        await auth.sendPasswordResetEmail(this.email)
        this.showSuccess = true
      } catch (err) {
        this.errorMsg = err.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;

  .subform {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
}
</style>
