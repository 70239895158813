<template>
  <div class="item-container">
    <div v-if="loading" class="spinner-div">
      <Spinner/>
    </div>
    <div v-else-if="product" class="item-view">
      <ProductCreateForm v-model="product" @back="$router.go(-1)" class="left-column" />
      <ItemInfoPanel v-model="product" class="right-column" />
    </div>
    <div class="submit-container">
      <v-btn @click="handleSubmit" color="primary" class="submit">Update</v-btn>
      <v-btn @click="handleDelete" color="error" class="delete">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <SnackAlert v-model="snackalert" />
    </div>
  </div>
</template>

<script>
import { productFirebaseApi, SimpleProduct } from '@/models/products.js'
import ProductCreateForm from '@/views/products/components/ProductCreateForm.vue'
import ItemInfoPanel from '@/components/shared/ItemInfoPanel.vue'
import SnackAlert from '@/components/shared/SnackAlert.vue'

export default {
  name: 'ProductDetailView',
  components: { ProductCreateForm, ItemInfoPanel, SnackAlert },
  props: [ 'id' ],
  data () {
    return {
      product: null,
      loading: true,
      snackalert: false
    }
  },
  methods: {
    async handleSubmit () {
      this.snackalert = true
      await productFirebaseApi.updateProduct({ ...this.product.toApi(), id: this.product.id })
      this.$router.push({ name: this.$strings.products.list.name })
    },
    async handleDelete () {
      if (await productFirebaseApi.deleteProduct(this.product.id)) {
        this.$bus.$emit('alert', 'Product was deleted!', 'info', null)
      } else {
        this.$bus.$emit('alert', 'Product could not be deleted!', 'error', null)
      }
      this.$router.push({ name: this.$strings.products.list.name })
    }
  },
  async created () {
    this.product = await productFirebaseApi.getProduct(this.id)
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.item-container {
  height: 100%;
  overflow: hidden;
  padding: 25px;

  .title {
    height: 10%;
    text-align: center;
    margin: 5px;
    font-size: 200%;
  }
  .item-view {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
    align-content: stretch;

    .left-column {
      display: flex;
      flex-basis: 63%;
      flex-direction: column;
      flex-grow: 2;
      height: auto;
    }
    .right-column {
      display: flex;
      flex-basis: 33%;
      flex-direction: column;
      flex-grow: 1;
      position: sticky;
      top: 0;
    }
  }
  .submit-container {
    padding-top: 20px;
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;

    .submit {
      color: white;
      flex-grow: 1;
    }
  }
  .spinner-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>